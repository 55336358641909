import React from 'react';
import { ChakraProvider, Box, SimpleGrid, Text, Flex, Button } from '@chakra-ui/react';
import  GeographyChart  from './Components/Map'; // Assume WorldMap is a custom component for the map
import  Transactions  from './Components/SalesTable/Transaction'; // Assume RecentTransactions is a custom component for transactions
import LineChart from './Components/LineChart/LineChart';
import SellerTransactions from './Components/SellersChart/SellerTable';
import StackedBarChart from './Components/SellersChart/StackedBarChart';

const Dashboard = () => {
  const barChartData = {
    labels: ['AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM'],
    datasets: [
      {
        label: 'Donut',
        data: [100, 150, 200, 250, 300, 350, 400],
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
      },
      {
        label: 'Fries',
        data: [200, 250, 300, 350, 400, 450, 500],
        backgroundColor: 'rgba(54,162,235,0.2)',
        borderColor: 'rgba(54,162,235,1)',
        borderWidth: 1,
      },
      {
        label: 'Kebab',
        data: [150, 200, 250, 300, 350, 400, 450],
        backgroundColor: 'rgba(255,206,86,0.2)',
        borderColor: 'rgba(255,206,86,1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Flex justify="space-between" align="center" mb={5}>
          <Text fontSize="2xl" fontWeight="bold">DASHBOARD</Text>
          <Button colorScheme="blue">Download</Button>
        </Flex>
        <SimpleGrid columns={{ sm: 2, md: 4 }} spacing={5} mb={5}>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow">Quantidade de Clientes</Text>
            <Text color="white" fontSize="2xl">12.361</Text>
            <Text color="green.400">+14%</Text>
          </Box>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow">Quantidade de Vendas</Text>
            <Text color="white" fontSize="2xl">431.225</Text>
            <Text color="green.400">+21%</Text>
          </Box>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow">Volume de Vendas</Text>
            <Text color="white" fontSize="2xl">R$ 32.441</Text>
            <Text color="green.400">+5%</Text>
          </Box>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow">Ticket Médio de vendas || Volume Vendas/Qtd. Vendas</Text>
            <Text color="white" fontSize="2xl">1.325.134</Text>
            <Text color="green.400">+43%</Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5}>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow" mb={5}>Volume</Text>
            <LineChart />
          </Box>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Transactions />
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5}>
          <Box bg="gray.700" p={2} borderRadius="md">
            <Text color="yellow" px={4} pt={3}>Rankings Vendedores</Text>
            <SellerTransactions />
          </Box>
          <Box bg="gray.700" p={2} borderRadius="md">
            <Text color="yellow" px={4} pt={3}>Quantidade de Vendas</Text>
            <StackedBarChart/>
          </Box>
        </SimpleGrid>
        <Box bg="gray.700" p={5} borderRadius="md">
          <Text color="yellow" mb={5}>Volume de Vendas em Nível Nacional - Mapa</Text>
          <GeographyChart />
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Dashboard;
