import React, { useState, useEffect, useCallback } from 'react';
import { Container, SimpleGrid, Heading, Text, Box, HStack, Button, Spinner, Center, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import FilterBar from '../FilterBar/FilterBar';
import ProductCard from '../Product/ProductCard';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ITEMS_PER_PAGE = 5;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchPage = () => {
  const query = useQuery();
  const searchQuery = query.get('query');

  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [sizes, setSizes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [genres, setGenres] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({
    sizes: new Set(),
    brands: [],
    colors: [],
    genres: [],
    itemTypes: [],
    categories: [],
    priceRange: [0, 1000],
    sortOption: 'latest-desc',
  });

  useEffect(() => {
    fetchData('sizes', setSizes);
    fetchData('brands', setBrands);
    fetchData('colors', setColors);
    fetchData('genders', setGenres);
    fetchData('itemTypes', setItemTypes);
    fetchData('categories', setCategories);
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [searchQuery, selectedFilters, page]);

  const fetchData = useCallback(async (endpoint, setState) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/${endpoint}`);
      const data = await response.json();
      setState(data);
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    try {
      const queryString = new URLSearchParams({
        query: searchQuery || '',
        page,
        limit: ITEMS_PER_PAGE,
        sizes: [...selectedFilters.sizes].join(','),
        brands: selectedFilters.brands.join(','),
        colors: selectedFilters.colors.join(','),
        genres: selectedFilters.genres.join(','),
        itemTypes: selectedFilters.itemTypes.join(','),
        categories: selectedFilters.categories.join(','),
        minPrice: selectedFilters.priceRange[0],
        maxPrice: selectedFilters.priceRange[1],
        sort: selectedFilters.sortOption,
      }).toString();

      const response = await fetch(`${API_BASE_URL}api/products/search?${queryString}`);
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(Array.isArray(data.products) ? data.products : []);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    } finally {
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on page change
    }
  }, [searchQuery, selectedFilters, page]);

  const handleFilterChange = useCallback((newFilters) => {
    setSelectedFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setPage(1); // Reset to first page after filter change
  }, []);

  const handleFirstPage = () => {
    setPage(1);
  };

  const handleLastPage = () => {
    setPage(totalPages);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 2);
    let endPage = Math.min(totalPages, page + 2);

    if (page <= 3) {
      startPage = 1;
      endPage = Math.min(totalPages, 5);
    } else if (page >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4);
      endPage = totalPages;
    }

    if (startPage > 1) {
      pageNumbers.push(
        <Button
          key="first"
          onClick={handleFirstPage}
          mx={1}
        >
          1
        </Button>
      );
      pageNumbers.push(<Box key="first-ellipsis" mx={1}>...</Box>);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          disabled={page === i}
          mx={1}
          bg={page === i ? 'blue.500' : 'transparent'}
          color={page === i ? 'white' : 'black'}
          _hover={{ bg: page === i ? 'blue.600' : 'gray.200' }}
        >
          {i}
        </Button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(<Box key="last-ellipsis" mx={1}>...</Box>);
      pageNumbers.push(
        <Button
          key="last"
          onClick={handleLastPage}
          mx={1}
        >
          {totalPages}
        </Button>
      );
    }

    return pageNumbers;
  };

  const paginatedProducts = products;

  return (
    <Box>
      <HStack align="start" spacing={8}>
        <FilterBar
          sizes={sizes}
          brands={brands}
          colors={colors}
          genres={genres}
          itemTypes={itemTypes}
          categories={categories}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
        />
        <Container maxW="7xl" mt={8}>
          <Heading mb={4}>
            Resultados da busca para "{searchQuery || 'todos os produtos'}"
          </Heading>
          {loading ? (
            <Center>
              <Spinner size="xl" mb={4}/>
            </Center>
          ) : paginatedProducts.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
              {paginatedProducts.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </SimpleGrid>
          ) : (
            <Text>Nenhum resultado encontrado</Text>
          )}
          <Flex justify="center" mt={8} align="center">
            {renderPageNumbers()}
          </Flex>
        </Container>
      </HStack>
    </Box>
  );
};

export default SearchPage;
