import React, { useState, useMemo } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import sellerMockData from './MockData';

const SellerTransactions = () => {
  const [sortConfig, setSortConfig] = useState({ key: 'seller', direction: 'ascending' });

  const sortedSellers = useMemo(() => {
    let sortableItems = [...sellerMockData];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <ChevronUpIcon /> : <ChevronDownIcon />;
    }
    return null;
  };

  return (
    <Box>
      <Box
        maxHeight="400px"
        overflowY="auto"
        p={3}
        borderRadius="md"
        bg="gray.700"
      >
        <Flex justify="space-between" bg="gray.800" p={3} borderRadius="md" mb={2}>
          <Text color="white" fontWeight="bold" cursor="pointer" onClick={() => handleSort('seller')}>
            Vendedores {getSortIcon('seller')}
          </Text>
          <Text color="white" fontWeight="bold" cursor="pointer" onClick={() => handleSort('clientsQuantity')}>
            Qtd. Clientes {getSortIcon('clientsQuantity')}
          </Text>
          <Text color="white" fontWeight="bold" cursor="pointer" onClick={() => handleSort('salesVolume')}>
            Volume Vendas {getSortIcon('salesVolume')}
          </Text>
          <Text color="white" fontWeight="bold" cursor="pointer" onClick={() => handleSort('tickets')}>
            Tickets {getSortIcon('tickets')}
          </Text>
        </Flex>
        {sortedSellers.map((seller, index) => (
          <Flex key={index} justify="space-between" bg="gray.600" p={3} borderRadius="md" mb={2}>
            <Text color="white">{seller.seller}</Text>
            <Text color="white">{seller.clientsQuantity}</Text>
            <Text color="green.400">{seller.salesVolume}</Text>
            <Text color="white">{seller.tickets}</Text>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default SellerTransactions;
