import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Heading, SimpleGrid, Text, Spinner, Flex } from '@chakra-ui/react';
import ProductCard from '../Product/ProductCard'; // Assume you have a ProductCard component to display product details
import FilterBarItemType from '../FilterBar/FilterBarItemType'; // Import the FilterBar component

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ITEMS_PER_PAGE = 5; // Adjust the number of items per page as needed

const ItemTypePage = () => {
  const navigate = useNavigate();
  const { itemTypeId } = useParams();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({
    sizes: new Set(),
    brands: [],
    colors: [],
    categories: [],
    priceRange: [0, 1000],
    sortOption: 'latest-desc',
  });
  const [sizes, setSizes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchProductsByItemType = useCallback(async () => {
    setIsLoading(true);
    try {
      const queryString = new URLSearchParams({
        page,
        limit: ITEMS_PER_PAGE,
        sizes: [...selectedFilters.sizes].join(','),
        brands: selectedFilters.brands.join(','),
        colors: selectedFilters.colors.join(','),
        categories: selectedFilters.categories.join(','),
        minPrice: selectedFilters.priceRange[0],
        maxPrice: selectedFilters.priceRange[1],
        sort: selectedFilters.sortOption,
      }).toString();
      const response = await fetch(`${API_BASE_URL}api/products/itemtype/${itemTypeId}?${queryString}`);
      const data = await response.json();
      setProducts(data.products);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  }, [itemTypeId, page, selectedFilters]);

  const fetchData = useCallback(async (endpoint, setState) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/${endpoint}`);
      const data = await response.json();
      setState(data);
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  }, [itemTypeId]);

  const fetchCategoriesByItemType = useCallback(async (itemTypeId) => {
    try {
      console.log(`Fetching categories for item type ID: ${itemTypeId}`);
      const response = await fetch(`${API_BASE_URL}api/categories/itemtype/${itemTypeId}`);
      const data = await response.json();
      setCategories(data);
      console.log('Fetched categories:', data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    fetchProductsByItemType();
  }, [itemTypeId, page, selectedFilters, fetchProductsByItemType]);

  useEffect(() => {
    fetchData('sizes', setSizes);
    fetchData('brands', setBrands);
    fetchData('colors', setColors);
    fetchCategoriesByItemType(itemTypeId);
  }, [itemTypeId, fetchData]);

  const handleFilterChange = (newFilters) => {
    setSelectedFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setPage(1); // Reset to first page after filter change
  };

  const handleFirstPage = () => {
    setPage(1);
  };

  const handleLastPage = () => {
    setPage(totalPages);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 2);
    let endPage = Math.min(totalPages, page + 2);

    if (page <= 3) {
      startPage = 1;
      endPage = Math.min(totalPages, 5);
    } else if (page >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4);
      endPage = totalPages;
    }

    if (startPage > 1) {
      pageNumbers.push(
        <Button
          key="first"
          onClick={handleFirstPage}
          mx={1}
        >
          1
        </Button>
      );
      pageNumbers.push(<Box key="first-ellipsis" mx={1}>...</Box>);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          disabled={page === i}
          mx={1}
          bg={page === i ? 'blue.500' : 'transparent'}
          color={page === i ? 'white' : 'black'}
          _hover={{ bg: page === i ? 'blue.600' : 'gray.200' }}
        >
          {i}
        </Button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(<Box key="last-ellipsis" mx={1}>...</Box>);
      pageNumbers.push(
        <Button
          key="last"
          onClick={handleLastPage}
          mx={1}
        >
          {totalPages}
        </Button>
      );
    }

    return pageNumbers;
  };

  return (
    <Flex direction="column" align="center" mt={8} width="100%">
      <Button onClick={() => navigate(-1)} mt={4} mb={4}>
        Voltar
      </Button>
      <Flex width="100%" justify="space-between">
        <Box width="20%">
          <FilterBarItemType
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            width="100%" // Ensure the FilterBar takes the full width of its container
            categories={categories}
            sizes={sizes}
            brands={brands}
            colors={colors}
          />
        </Box>
        <Box flex="1" ml={8}>
          <Heading mb={4}>Produtos</Heading>
          {isLoading ? (
            <Flex justify="center">
              <Spinner size="xl" mb={4} />
            </Flex>
          ) : products.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
              {products.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </SimpleGrid>
          ) : (
            <Text>Nenhum produto encontrado</Text>
          )}
          {!isLoading && (
            <Flex justify="center" mt={4} mb={4} align="center">
              {renderPageNumbers()}
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ItemTypePage;
