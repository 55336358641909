import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Stack,
  useToast,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import './SellerManagerDashBoard.css'; // Import the new CSS file
import { FaCheck, FaTimes } from 'react-icons/fa';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const SellerManagerDashboard = () => {
  const [firstOrders, setFirstOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchFirstOrders = async () => {
      const response = await fetch(`${API_BASE_URL}api/orders/first`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setFirstOrders(data.reverse());
      } else {
        console.error('Failed to fetch first orders');
      }
    };

    fetchFirstOrders();
  }, []);

  const handleApproveFirstOrder = async (orderId) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/orders/approve-first-order/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const updatedOrder = await response.json();
        setFirstOrders(prevOrders =>
          prevOrders.map(order =>
            order._id === orderId ? { ...order, status: updatedOrder.status } : order
          )
        );
        toast({
          title: 'Order approved by manager',
          description: 'Order has been approved by the manager.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Failed to approve order');
        toast({
          title: 'Error',
          description: 'Failed to approve order.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error approving order:', error);
      toast({
        title: 'Error',
        description: 'Failed to approve order.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateStatus = async (orderId, status) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/orders/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ status }),
      });

      if (response.ok) {
        const updatedOrder = await response.json();
        setFirstOrders(prevOrders =>
          prevOrders.map(order =>
            order._id === orderId ? { ...order, status: updatedOrder.status } : order
          )
        );
        toast({
          title: 'Status updated',
          description: 'Order status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Failed to update order status');
        toast({
          title: 'Error',
          description: 'Failed to update order status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update order status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/orders/${orderId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        setFirstOrders(prevOrders => prevOrders.filter(order => order._id !== orderId));
        toast({
          title: 'Order deleted',
          description: 'Order has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Failed to delete order');
        toast({
          title: 'Error',
          description: 'Failed to delete order.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting order:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete order.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <Box className="seller-dashboard" p={4}>
      <Heading mb={4}>Gerente DashBoard</Heading>
      <Tabs isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab>Aprovações de Primeiro Pedido</Tab>
          <Tab>BI DashBoard</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Heading mb={4}>Aprovação de Primeiro Pedido</Heading>
            <Table className="table" variant="striped">
              <Thead>
                <Tr>
                  <Th>Pedido</Th>
                  <Th>Status</Th>
                  <Th>Data</Th>
                  <Th>Cliente</Th>
                  <Th>Telefone</Th>
                  <Th>Pagamento</Th>
                  <Th>Total</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {firstOrders.map(order => (
                  <Tr key={order._id}>
                    <Td>
                      <a onClick={() => handleOpenModal(order)}>
                        #{order._id}
                      </a>
                    </Td>
                    <Td>{order.status}</Td>
                    <Td>{new Date(order.createdAt).toLocaleString()}</Td>
                    <Td>{order.userId ? order.userId.name || '' : ''}</Td>
                    <Td>{order.userId ? order.userId.phone || '' : ''}</Td>
                    <Td>{order.paymentMethod}</Td>
                    <Td>R${order.items.reduce((total, item) => total + (item.price ? item.price * item.quantity : 0), 0).toFixed(2)}</Td>
                    <Td>
                      <Stack direction="row" spacing={4}>
                        {order.status === 'aguardando_aval_gestor' && (
                          <>
                            <Button className="approve-button" size="sm" onClick={() => handleApproveFirstOrder(order._id)}>
                              <FaCheck/>
                            </Button>
                            <Button className="reject-button" size="sm" onClick={() => handleUpdateStatus(order._id, 'rejeitado_gestor')}>
                              <FaTimes /> 
                            </Button>
                          </>
                        )}
                        <Button className="reject-button" size="sm" onClick={() => handleDeleteOrder(order._id)}>Deletar</Button>
                      </Stack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>

          <TabPanel>
            <Heading mb={4}>Inteligência de Negócios</Heading>
            <Box>
              <Flex>
                <Box w="25%" p={4} borderWidth={1} borderRadius="lg" mr={4}>
                  <Text fontSize="2xl" fontWeight="bold">7</Text>
                  <Text>Total de Pedidos</Text>
                </Box>
                <Box w="25%" p={4} borderWidth={1} borderRadius="lg" mr={4}>
                  <Text fontSize="2xl" fontWeight="bold">4</Text>
                  <Text>Delivery</Text>
                </Box>
                <Box w="25%" p={4} borderWidth={1} borderRadius="lg" mr={4}>
                  <Text fontSize="2xl" fontWeight="bold">3</Text>
                  <Text>Retirada</Text>
                </Box>
                <Box w="25%" p={4} borderWidth={1} borderRadius="lg">
                  <Text fontSize="2xl" fontWeight="bold">R$ 2490,82</Text>
                  <Text>Faturado</Text>
                </Box>
              </Flex>
              <Flex mt={4}>
                <Box w="50%" p={4} borderWidth={1} borderRadius="lg" mr={4}>
                  <Text>Faturamento por dia da semana</Text>
                  {/* Insert Chart Component */}
                </Box>
                <Box w="50%" p={4} borderWidth={1} borderRadius="lg">
                  <Text>Pedidos por dia da semana</Text>
                  {/* Insert Chart Component */}
                </Box>
              </Flex>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Pedido</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedOrder && (
              <Box>
                <Text><strong>Pedido ID:</strong> {selectedOrder._id}</Text>
                <Text><strong>Status:</strong> {selectedOrder.status}</Text>
                <Text><strong>Data do pedido:</strong> {new Date(selectedOrder.createdAt).toLocaleString()}</Text>
                <Text><strong>Cliente:</strong> {selectedOrder.userId ? selectedOrder.userId.name || '' : ''}</Text>
                <Text><strong>Telefone:</strong> {selectedOrder.userId ? selectedOrder.userId.phone || '' : ''}</Text>
                <Text><strong>Email:</strong> {selectedOrder.userId ? selectedOrder.userId.email || '' : ''}</Text>
                <Text><strong>Endereço:</strong> {selectedOrder.shippingAddress.endereco}, {selectedOrder.shippingAddress.numero}, {selectedOrder.shippingAddress.complemento}, {selectedOrder.shippingAddress.bairro}, {selectedOrder.shippingAddress.cidade}, {selectedOrder.shippingAddress.estado}, {selectedOrder.shippingAddress.pais}</Text>
                <Text><strong>CEP:</strong> {selectedOrder.shippingAddress.cep}</Text>
                <Text><strong>Total:</strong> R${selectedOrder.items.reduce((total, item) => total + (item.price ? item.price * item.quantity : 0), 0).toFixed(2)}</Text>
                <Text><strong>Notas:</strong> {selectedOrder.notes}</Text>
                <Text><strong>Itens:</strong></Text>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Produto</Th>
                      <Th>Cor</Th>
                      <Th>Tamanho</Th>
                      <Th>Quantidade</Th>
                      <Th>Preço Unidade</Th>
                      <Th>Preço Total</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedOrder.items.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.product.name}</Td>
                        <Td>
                          <Flex alignItems="center">
                            <Box
                              bg={item.color ? item.color.hex : 'transparent'}
                              w="20px"
                              h="20px"
                              borderRadius="full"
                              ml={2}
                              mr={2}
                              border="1px solid gray"
                            />
                            <Text>{item.color ? item.color.name : 'N/A'}</Text>
                          </Flex>
                        </Td>
                        <Td>{item.size ? item.size.name : 'N/A'}</Td>
                        <Td>{item.quantity}</Td>
                        <Td>{item.price ? `R$${item.price.toFixed(2)}` : 'N/A'}</Td>
                        <Td>{item.price ? `R$${(item.price * item.quantity).toFixed(2)}` : 'N/A'}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SellerManagerDashboard;
