import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Heading, Flex, Text } from '@chakra-ui/react';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const ManageFooterLinks = () => {
  const [footerLinks, setFooterLinks] = useState([]);
  const [currentLink, setCurrentLink] = useState({ title: '', url: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchFooterLinks();
  }, []);

  const fetchFooterLinks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/footerlinks`);
      const data = await response.json();
      setFooterLinks(data);
    } catch (error) {
      console.error('Failed to fetch footer links:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentLink({ ...currentLink, [name]: value });
  };

  const handleAddFooterLink = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/footerlinks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentLink),
      });

      if (!response.ok) {
        throw new Error('Failed to add footer link');
      }

      fetchFooterLinks();
      setCurrentLink({ title: '', url: '' });
    } catch (error) {
      console.error('Error adding footer link:', error);
    }
  };

  const handleUpdateFooterLink = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/footerlinks/${editingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentLink),
      });

      if (!response.ok) {
        throw new Error('Failed to update footer link');
      }

      fetchFooterLinks();
      setCurrentLink({ title: '', url: '' });
      setIsEditing(false);
      setEditingId(null);
    } catch (error) {
      console.error('Error updating footer link:', error);
    }
  };

  const handleDeleteFooterLink = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/footerlinks/${id}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete footer link');
      }
  
      fetchFooterLinks();
    } catch (error) {
      console.error('Error deleting footer link:', error);
    }
  };  

  const startEditing = (id, link) => {
    setIsEditing(true);
    setEditingId(id);
    setCurrentLink(link);
  };

  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Manage Footer Links</Heading>
      <Flex direction="column" gap={4}>
        <Input
          placeholder="Title"
          name="title"
          value={currentLink.title}
          onChange={handleInputChange}
        />
        <Input
          placeholder="URL"
          name="url"
          value={currentLink.url}
          onChange={handleInputChange}
        />
        {isEditing ? (
          <Button onClick={handleUpdateFooterLink} colorScheme="blue">Update Footer Link</Button>
        ) : (
          <Button onClick={handleAddFooterLink} colorScheme="blue">Add Footer Link</Button>
        )}
      </Flex>
      <Box mt={4}>
        {footerLinks.map((link) => (
          <Flex key={link._id} justifyContent="space-between" alignItems="center" mb={2}>
            <Box>
              <Text fontWeight="bold">{link.title}</Text>
              <Text>{link.url}</Text>
            </Box>
            <Flex>
              <Button onClick={() => startEditing(link._id, link)} colorScheme="yellow" mr={2}>Edit</Button>
              <Button onClick={() => handleDeleteFooterLink(link._id)} colorScheme="red">Delete</Button>
            </Flex>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default ManageFooterLinks;
