import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  IconButton,
  Image,
  Text,
  Select,
  Flex, // Ensure Flex is imported
  useToast,
  Link as ChakraLink // Ensure Link is imported
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link as RouterLink } from 'react-router-dom';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ManageUpperCarousel = () => {
  const [carouselItems, setCarouselItems] = useState([]);
  const [newItem, setNewItem] = useState({ imageUrl: '', order: 0, name: '', size: 'medium' });
  const [images, setImages] = useState([]);
  const [selectedSize, setSelectedSize] = useState('medium'); // Default size
  const toast = useToast();

  useEffect(() => {
    fetchCarouselItems();
  }, []);

  const fetchCarouselItems = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/uppercarousel`);
      const data = await response.json();
      setCarouselItems(data.sort((a, b) => a.order - b.order)); // Sort items by order
      if (data.length > 0) {
        setSelectedSize(data[0].size || 'medium'); // Assume all items have the same size, set default if not present
      }
    } catch (error) {
      console.error('Error fetching carousel items:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
  };

  const handleImageChange = (e) => {
    setImages([...images, ...Array.from(e.target.files)]);
  };

  const handleSizeChange = (e) => {
    const size = e.target.value;
    setSelectedSize(size);
    setNewItem({ ...newItem, size });
  };

  const addItem = async () => {
    if (carouselItems.length >= 5) {
      alert('You can only add up to 5 carousel items.');
      return;
    }

    const formData = new FormData();
    formData.append('order', newItem.order);
    formData.append('name', newItem.name);
    formData.append('size', selectedSize); // Save selected size
    images.forEach(image => formData.append('images', image));

    try {
      const response = await fetch(`${API_BASE_URL}api/uppercarousel`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to add carousel item');
      }

      const createdItem = await response.json();
      setCarouselItems([...carouselItems, ...createdItem]);
      setNewItem({ imageUrl: '', order: 0, name: '', size: 'medium' });
      setImages([]);
      fetchCarouselItems(); // Fetch updated list
    } catch (error) {
      console.error('Error adding carousel item:', error);
      toast({
        title: 'Error',
        description: 'Failed to add carousel item',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deleteItem = async (index) => {
    const itemId = carouselItems[index]._id;

    try {
      const response = await fetch(`${API_BASE_URL}api/uppercarousel/${itemId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete carousel item');
      }

      const updatedItems = [...carouselItems];
      updatedItems.splice(index, 1);
      setCarouselItems(updatedItems);
      fetchCarouselItems(); // Fetch updated list after deletion
    } catch (error) {
      console.error('Error deleting carousel item:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete carousel item',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(carouselItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCarouselItems(items);
  };

  return (
    <Box>
      <VStack spacing={4}>
        <FormControl>
          <FormLabel>Nome do Carousel Item</FormLabel>
          <Input
            type="text"
            name="name"
            value={newItem.name}
            onChange={handleInputChange}
          />
          <FormLabel>Upload de Imagem</FormLabel>
          <Input
            type="file"
            accept="image/*"
            single
            onChange={handleImageChange}
          />
          <FormLabel>Selecione o tamanho do Carousel</FormLabel>
          <Select value={selectedSize} onChange={handleSizeChange}>
            <option value="small">Small</option>
            <option value="medium">Medium (Default)</option>
            <option value="large">Large</option>
          </Select>
        </FormControl>
        <Box>
          <Text>Nova Imagem</Text>
          <Flex>
            {images.map((image, index) => (
              <Box key={index} position="relative" mr={2}>
                <img
                  src={URL.createObjectURL(image)}
                  alt="new-carousel"
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
                <Button
                  size="xs"
                  colorScheme="red"
                  position="absolute"
                  top="0"
                  right="0"
                  onClick={() => removeImage(index)}
                >
                  X
                </Button>
              </Box>
            ))}
          </Flex>
        </Box>

        <Button leftIcon={<AddIcon />} onClick={addItem}>
          Add Item
        </Button>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="carousel" direction="horizontal">
            {(provided) => (
              <HStack {...provided.droppableProps} ref={provided.innerRef} spacing={2}>
                {carouselItems.map((item, index) => (
                  <Draggable key={item._id} draggableId={item._id.toString()} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        w="full"
                        p={2}
                        border="1px solid gray"
                        borderRadius="md"
                      >
                        <HStack w="full">
                          <Text fontWeight="bold">Order:</Text>
                          <Text>{item.order}</Text>
                        </HStack>
                        <Text fontWeight="bold">{item.name}</Text>
                        <Image
                          src={`data:image/png;base64,${item.imageUrl}`}
                          alt={`Carousel image ${index}`}
                          boxSize="150px"
                        />
                        <HStack>
                          <Text fontWeight="bold">Size:</Text>
                          <Text>{item.size || 'medium'}</Text> {/* Display the size */}
                          <ChakraLink as={RouterLink} to={`/related-products/${item._id}`} color="blue.500">
                            View Related Products
                          </ChakraLink>
                          <IconButton color={"red"} icon={<DeleteIcon />} onClick={() => deleteItem(index)} />
                        </HStack>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </HStack>
            )}
          </Droppable>
        </DragDropContext>
      </VStack>
    </Box>
  );
};

export default ManageUpperCarousel;
