import jsPDF from 'jspdf';

export const generatePdf = (order) => {
  const doc = new jsPDF();
  const margin = 10;
  const lineHeight = 10;
  let y = margin;

  // Add title
  doc.setFontSize(18);
  doc.text('Detalhes do Pedido', margin, y);
  y += lineHeight + 5;

  // Add order details
  doc.setFontSize(12);
  doc.text(`Pedido ID: ${order._id}`, margin, y);
  y += lineHeight;
  doc.text(`Status: ${order.status}`, margin, y);
  y += lineHeight;
  doc.text(`Data do pedido: ${new Date(order.createdAt).toLocaleString()}`, margin, y);
  y += lineHeight;
  doc.text(`Cliente: ${order.userId ? order.userId.name || '' : ''}`, margin, y);
  y += lineHeight;
  doc.text(`Telefone: ${order.userId ? order.userId.phone || '' : ''}`, margin, y);
  y += lineHeight;
  doc.text(`Email: ${order.userId ? order.userId.email || '' : ''}`, margin, y);
  y += lineHeight;
  doc.text(`Endereço: ${order.shippingAddress.endereco}, ${order.shippingAddress.numero}, ${order.shippingAddress.complemento}, ${order.shippingAddress.bairro}, ${order.shippingAddress.cidade}, ${order.shippingAddress.estado}, ${order.shippingAddress.pais}`, margin, y);
  y += lineHeight;
  doc.text(`CEP: ${order.shippingAddress.cep}`, margin, y);
  y += lineHeight;
  doc.text(`Valor Total dos itens da Compra: R$${order.items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}`, margin, y);
  y += lineHeight;
  doc.text(`Valor do frete: R$${order.shippingCost.toFixed(2)}`, margin, y);
  y += lineHeight;
  doc.text(`Notas: ${order.notes}`, margin, y);
  y += lineHeight + 5;

  // Add table header
  doc.setFontSize(14);
  doc.text('Itens do Pedido', margin, y);
  y += lineHeight + 5;
  doc.setFontSize(12);
  doc.text('Produto', margin, y);
  doc.text('Cor', margin + 40, y);
  doc.text('Tamanho', margin + 80, y);
  doc.text('Quantidade', margin + 120, y);
  doc.text('Preço Unidade', margin + 160, y);
  doc.text('Preço Total', margin + 200, y);
  y += lineHeight;

  // Add table rows
  order.items.forEach((item) => {
    doc.text(item.product.name, margin, y);
    doc.text(item.color.name, margin + 40, y);
    doc.text(item.size.name, margin + 80, y);
    doc.text(item.quantity.toString(), margin + 120, y);
    doc.text(`R$${item.price.toFixed(2)}`, margin + 160, y);
    doc.text(`R$${(item.price * item.quantity).toFixed(2)}`, margin + 200, y);
    y += lineHeight;
  });

  // Save the PDF
  doc.save('download.pdf');
};

export const printOrder = (order) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Detalhes do Pedido</title>
          <style>
            body { font-family: Arial, sans-serif; }
            h1, h2 { text-align: center; }
            p { margin: 5px 0; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: 1px solid #000; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
            .column-produto { width: 30%; }
            .column-cor { width: 15%; }
            .column-tamanho { width: 15%; }
            .column-quantidade { width: 10%; }
            .column-preco-unidade { width: 15%; }
            .column-preco-total { width: 15%; }
          </style>
        </head>
        <body>
          <h1>Detalhes do Pedido</h1>
          <p>Pedido ID: ${order._id}</p>
          <p>Status: ${order.status}</p>
          <p>Data do pedido: ${new Date(order.createdAt).toLocaleString()}</p>
          <p>Cliente: ${order.userId ? order.userId.name || '' : ''}</p>
          <p>Telefone: ${order.userId ? order.userId.phone || '' : ''}</p>
          <p>Email: ${order.userId ? order.userId.email || '' : ''}</p>
          <p>Endereço: ${order.shippingAddress.endereco}, ${order.shippingAddress.numero}, ${order.shippingAddress.complemento}, ${order.shippingAddress.bairro}, ${order.shippingAddress.cidade}, ${order.shippingAddress.estado}, ${order.shippingAddress.pais}</p>
          <p>CEP: ${order.shippingAddress.cep}</p>
          <p>Valor Total dos itens da Compra: R$${order.items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}</p>
          <p>Valor do frete: R$${order.shippingCost.toFixed(2)}</p>
          <p>Notas: ${order.notes}</p>
          <h2>Itens do Pedido</h2>
          <table>
            <thead>
              <tr>
                <th class="column-produto">Produto</th>
                <th class="column-cor">Cor</th>
                <th class="column-tamanho">Tamanho</th>
                <th class="column-quantidade">Quantidade</th>
                <th class="column-preco-unidade">Preço Unidade</th>
                <th class="column-preco-total">Preço Total</th>
              </tr>
            </thead>
            <tbody>
              ${order.items.map(item => `
                <tr>
                  <td class="column-produto">${item.product.name}</td>
                  <td class="column-cor">${item.color.name}</td>
                  <td class="column-tamanho">${item.size.name}</td>
                  <td class="column-quantidade">${item.quantity}</td>
                  <td class="column-preco-unidade">R$${item.price.toFixed(2)}</td>
                  <td class="column-preco-total">R$${(item.price * item.quantity).toFixed(2)}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  
