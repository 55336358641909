import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Link,
  useTheme,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const AccountPage = () => {
  const theme = useTheme();
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editUser, setEditUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}api/users/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUser(data);
          setEditUser({ name: data.name, email: data.email });
          fetchOrders(data._id);
        } else {
          console.error('Failed to fetch user profile');
          navigate('/login'); // Redirect to login if not authorized
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/login');
      }
    };

    const fetchOrders = async (userId) => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}api/orders/user-orders/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setOrders(data);
        } else {
          console.error('Failed to fetch orders', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchUser();
  }, [navigate]);

  const handleOpenOrderModal = (order) => {
    setSelectedOrder(order);
    setIsOrderModalOpen(true);
  };

  const handleOrderModalClose = () => {
    setIsOrderModalOpen(false);
    setSelectedOrder(null);
  };

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg="white">
      <Stack spacing={8} mx={'auto'} maxW={'5xl'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Minha conta</Heading>
        </Stack>
        <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" w="100%">
          <Box rounded={'lg'} bg={theme.colors.brand[500]} boxShadow={'lg'} p={8} mb={{ base: 4, md: 0 }} minW="300px" mr={2}>
            <Heading fontSize={'2xl'} mb={4}>Dados</Heading>
            <Stack spacing={4}>
              <Box>
                <Text fontWeight="bold">Dados Pessoais</Text>
                <Text>{user.name}</Text>
                <Text>{user.email}</Text>
              </Box>
              <Box>
                <Link color={'blue.400'} onClick={() => setIsEditModalOpen(true)}>Editar</Link>
              </Box>
            </Stack>
          </Box>
          <Box rounded={'lg'} bg={theme.colors.brand[500]} boxShadow={'lg'} p={8} flex={1}> 
            <Heading fontSize={'2xl'} mb={4}>Minhas Compras</Heading>
            {orders.length === 0 ? (
              <Stack spacing={4} align={'center'}>
                <Text fontSize={'lg'} color={'gray.600'}>
                  Realize sua primeira compra!
                </Text>
                <Button
                  as={RouterLink}
                  to="/"
                  bg={'green.400'}
                  color={'white'}
                  _hover={{ bg: 'green.500' }}
                >
                  IR PARA A LOJA
                </Button>
              </Stack>
            ) : (
              <Box maxH="400px" overflowY="auto">
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Pedido ID</Th>
                      <Th>Status</Th>
                      <Th>Data do Pedido</Th>
                      <Th>Total</Th>
                      <Th>Ações</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {orders.map(order => (
                      <Tr key={order._id}>
                        <Td>{order._id}</Td>
                        <Td>{order.status}</Td>
                        <Td>{new Date(order.createdAt).toLocaleString()}</Td>
                        <Td>R${order.items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}</Td>
                        <Td>
                          <Button variant="link" onClick={() => handleOpenOrderModal(order)}>Ver detalhes</Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>
        </Flex>
      </Stack>

      {selectedOrder && (
        <Modal isOpen={isOrderModalOpen} onClose={handleOrderModalClose} size="6xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Detalhes do Pedido</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Text><strong>Pedido ID:</strong> {selectedOrder._id}</Text>
                <Text><strong>Status:</strong> {selectedOrder.status}</Text>
                <Text><strong>Data do pedido:</strong> {new Date(selectedOrder.createdAt).toLocaleString()}</Text>
                <Text><strong>Cliente:</strong> {selectedOrder.userId.name}</Text>
                <Text><strong>Telefone:</strong> {selectedOrder.userId.phone}</Text>
                <Text><strong>Email:</strong> {selectedOrder.userId.email}</Text>
                <Text><strong>Endereço:</strong> {selectedOrder.shippingAddress.endereco}, {selectedOrder.shippingAddress.numero}, {selectedOrder.shippingAddress.complemento}, {selectedOrder.shippingAddress.bairro}, {selectedOrder.shippingAddress.cidade}, {selectedOrder.shippingAddress.estado}, {selectedOrder.shippingAddress.pais}</Text>
                <Text><strong>CEP:</strong> {selectedOrder.shippingAddress.cep}</Text>
                <Text><strong>Total:</strong> R${selectedOrder.items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}</Text>
                <Text><strong>Notas:</strong> {selectedOrder.notes}</Text>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Produto</Th>
                      <Th>Cor</Th>
                      <Th>Tamanho</Th>
                      <Th>Quantidade</Th>
                      <Th>Preço Unidade</Th>
                      <Th>Preço Total</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedOrder.items.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.product.name}</Td>
                        <Td>
                          <Flex alignItems="center">
                            <Box
                              bg={item.color.hex}
                              w="20px"
                              h="20px"
                              borderRadius="full"
                              ml={2}
                              mr={2}
                              border="1px solid gray"
                            />
                            <Text>{item.color.name}</Text>
                          </Flex>
                        </Td>
                        <Td>{item.size.name}</Td>
                        <Td>{item.quantity}</Td>
                        <Td>R${item.price.toFixed(2)}</Td>
                        <Td>R${(item.price * item.quantity).toFixed(2)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleOrderModalClose}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};

export default AccountPage;
