import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  IconButton,
  Text,
  Checkbox,
  Divider,
  Select,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ManageNavItems = () => {
  const [userNavItems, setUserNavItems] = useState([]);
  const [generatedNavItems, setGeneratedNavItems] = useState([]);
  const [newNavItem, setNewNavItem] = useState({ label: '', subLabel: '', children: [] });
  const [editingIndex, setEditingIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [generos, setGeneros] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [childInput, setChildInput] = useState({ label: '', subLabel: '', href: '' });

  useEffect(() => {
    const fetchUserNavItems = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/navitems`);
        const data = await response.json();
        setUserNavItems(data);
      } catch (error) {
        console.error('Error fetching user nav items:', error);
      }
    };

    const fetchNavData = async () => {
      try {
        const generoResponse = await fetch(`${API_BASE_URL}api/genders`);
        const generoData = await generoResponse.json();
        setGeneros(generoData);

        const itemTypeResponse = await fetch(`${API_BASE_URL}api/itemtypes`);
        const itemTypeData = await itemTypeResponse.json();
        setItemTypes(itemTypeData);

        const generatedItems = generoData.map(genero => {
          const children = itemTypeData
            .filter(itemType => itemType.gender === genero._id)
            .map(itemType => ({
              label: itemType.name,
              href: `/items/${itemType._id}`,
              subLabel: '',
            }));

          return {
            label: genero.name,
            href: `/genero/${genero._id}`,
            subLabel: '',
            children,
            active: false,
          };
        });

        setGeneratedNavItems(generatedItems);
      } catch (error) {
        console.error('Error fetching navigation data:', error);
      }
    };

    fetchUserNavItems();
    fetchNavData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewNavItem({ ...newNavItem, [name]: value });
  };

  const handleChildInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedChildren = [...newNavItem.children];
    updatedChildren[index][name] = value;
    setNewNavItem({ ...newNavItem, children: updatedChildren });
  };

  const generateHref = (label) => `/custom/${label.toLowerCase().replace(/ /g, '-')}`;

  const addNavItem = async () => {
    try {
      const navItemToAdd = { 
        ...newNavItem, 
        href: generateHref(newNavItem.label),
        children: newNavItem.children.map(child => ({
          ...child,
          href: generateHref(child.label),
        }))
      };

      const response = await fetch(`${API_BASE_URL}api/navitems`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(navItemToAdd),
      });

      if (!response.ok) {
        throw new Error('Failed to add nav item');
      }

      const createdNavItem = await response.json();
      setUserNavItems([...userNavItems, createdNavItem]);
      setNewNavItem({ label: '', subLabel: '', children: [] });
    } catch (error) {
      console.error('Error adding nav item:', error);
    }
  };

  const updateNavItem = async () => {
    try {
      const navItemToUpdate = { 
        ...newNavItem, 
        href: generateHref(newNavItem.label),
        children: newNavItem.children.map(child => ({
          ...child,
          href: generateHref(child.label),
        }))
      };

      const response = await fetch(`${API_BASE_URL}api/navitems/${newNavItem._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(navItemToUpdate),
      });

      if (!response.ok) {
        throw new Error('Failed to update nav item');
      }

      const updatedNavItem = await response.json();
      const updatedNavItems = [...userNavItems];
      updatedNavItems[editingIndex] = updatedNavItem;
      setUserNavItems(updatedNavItems);
      setNewNavItem({ label: '', subLabel: '', children: [] });
      setEditingIndex(null);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating nav item:', error);
    }
  };

  const deleteNavItem = async (index) => {
    const updatedNavItems = [...userNavItems];
    const navItemId = updatedNavItems[index]._id;

    try {
      const response = await fetch(`${API_BASE_URL}api/navitems/${navItemId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete nav item');
      }

      updatedNavItems.splice(index, 1);
      setUserNavItems(updatedNavItems);
    } catch (error) {
      console.error('Error deleting nav item:', error);
    }
  };

  const handleCheckboxChange = async (index) => {
    const updatedGeneratedItems = [...generatedNavItems];
    updatedGeneratedItems[index].active = !updatedGeneratedItems[index].active;

    if (updatedGeneratedItems[index].active) {
      try {
        const response = await fetch(`${API_BASE_URL}api/navitems`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedGeneratedItems[index]),
        });

        if (!response.ok) {
          throw new Error('Failed to add nav item');
        }

        const createdNavItem = await response.json();
        setUserNavItems([...userNavItems, createdNavItem]);
      } catch (error) {
        console.error('Error adding nav item:', error);
      }
    } else {
      const navItemId = updatedGeneratedItems[index]._id;
      const userItemIndex = userNavItems.findIndex(item => item._id === navItemId);
      if (userItemIndex > -1) {
        try {
          const response = await fetch(`${API_BASE_URL}api/navitems/${navItemId}`, {
            method: 'DELETE',
          });

          if (!response.ok) {
            throw new Error('Failed to delete nav item');
          }

          const updatedUserItems = [...userNavItems];
          updatedUserItems.splice(userItemIndex, 1);
          setUserNavItems(updatedUserItems);
        } catch (error) {
          console.error('Error deleting nav item:', error);
        }
      }
    }

    setGeneratedNavItems(updatedGeneratedItems);
  };

  const startEditing = (index) => {
    setNewNavItem(userNavItems[index]);
    setEditingIndex(index);
    setIsEditing(true);
  };

  const addChild = () => {
    setNewNavItem({ 
      ...newNavItem, 
      children: [
        ...newNavItem.children, 
        { ...childInput, href: generateHref(childInput.label) }
      ] 
    });
    setChildInput({ label: '', subLabel: '', href: '' });
  };

  const removeChild = (childIndex) => {
    const updatedChildren = newNavItem.children.filter((_, index) => index !== childIndex);
    setNewNavItem({ ...newNavItem, children: updatedChildren });
  };

  const handleGeneroChange = (e) => {
    const generoId = e.target.value;
    const selectedGenero = generos.find(genero => genero._id === generoId);
    const relatedItemTypes = itemTypes.filter(itemType => itemType.gender._id === generoId);

    setNewNavItem({
      label: selectedGenero.name,
      href: generateHref(selectedGenero.name),
      subLabel: '',
      children: relatedItemTypes.map(itemType => ({
        label: itemType.name,
        href: generateHref(itemType.name),
        subLabel: '',
      }))
    });
  };

  return (
    <Box>
      <VStack spacing={4}>
        <Text fontSize="2xl">Manage User Navigation Items</Text>
        {userNavItems.map((item, index) => (
          <VStack key={item._id} spacing={2} w="full" p={2} border="1px solid gray" borderRadius="md">
            <HStack w="full">
              <Text fontWeight="bold">Label:</Text>
              <Text>{item.label}</Text>
            </HStack>
            <HStack w="full">
              <Text fontWeight="bold">Href:</Text>
              <Text>{item.href}</Text>
            </HStack>
            {item.children && item.children.length > 0 && (
              <Box w="full" pl={4}>
                {item.children.map((child, childIndex) => (
                  <VStack key={childIndex} w="full">
                    <HStack w="full">
                      <Text>Label: {child.label}</Text>
                      <Text>Href: {child.href}</Text>
                      <Text>SubLabel: {child.subLabel}</Text>
                    </HStack>
                  </VStack>
                ))}
              </Box>
            )}
            <HStack>
              <IconButton icon={<EditIcon />} onClick={() => startEditing(index)} />
              <IconButton icon={<DeleteIcon />} onClick={() => deleteNavItem(index)} />
            </HStack>
          </VStack>
        ))}
        <FormControl>
          <FormLabel>Genero</FormLabel>
          <Select placeholder="Select Genero" onChange={handleGeneroChange}>
            {generos.map(genero => (
              <option key={genero._id} value={genero._id}>
                {genero.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Label</FormLabel>
          <Input
            name="label"
            value={newNavItem.label}
            onChange={handleInputChange}
          />
          <FormLabel>Children</FormLabel>
          {newNavItem.children.map((child, index) => (
            <Box key={index} pl={4} mb={2}>
              <Divider />
              <HStack>
                <Text>Child {index + 1}</Text>
                <IconButton icon={<DeleteIcon />} size="sm" onClick={() => removeChild(index)} />
              </HStack>
              <FormLabel>Child Label</FormLabel>
              <Input
                name="label"
                value={child.label}
                onChange={(e) => handleChildInputChange(e, index)}
              />
              <FormLabel>Child SubLabel</FormLabel>
              <Input
                name="subLabel"
                value={child.subLabel}
                onChange={(e) => handleChildInputChange(e, index)}
              />
            </Box>
          ))}
          <Box pl={4} mb={2}>
            <FormLabel>New Child Label</FormLabel>
            <Input
              name="label"
              value={childInput.label}
              onChange={(e) => setChildInput({ ...childInput, label: e.target.value })}
            />
            <FormLabel>New Child SubLabel</FormLabel>
            <Input
              name="subLabel"
              value={childInput.subLabel}
              onChange={(e) => setChildInput({ ...childInput, subLabel: e.target.value })}
            />
            {/* The href will be generated internally */}
          </Box>
          <Button leftIcon={<AddIcon />} onClick={addChild}>
            Add Child
          </Button>
          <HStack>
            {isEditing ? (
              <Button leftIcon={<EditIcon />} onClick={updateNavItem}>
                Update Nav Item
              </Button>
            ) : (
              <Button leftIcon={<AddIcon />} onClick={addNavItem}>
                Add Nav Item
              </Button>
            )}
            {isEditing && (
              <Button leftIcon={<DeleteIcon />} onClick={() => {
                setNewNavItem({ label: '', subLabel: '', children: [] });
                setEditingIndex(null);
                setIsEditing(false);
              }}>
                Cancel
              </Button>
            )}
          </HStack>
        </FormControl>
        <Text fontSize="2xl">Generated Navigation Items</Text>
        {generatedNavItems.map((item, index) => (
          <VStack key={item.label} spacing={2} w="full" p={2} border="1px solid gray" borderRadius="md">
            <HStack w="full">
              <Checkbox isChecked={item.active} onChange={() => handleCheckboxChange(index)}>
                <Text fontWeight="bold">{item.label}</Text>
              </Checkbox>
            </HStack>
            {item.children && item.children.length > 0 && (
              <Box w="full" pl={4}>
                {item.children.map((child, childIndex) => (
                  <HStack key={childIndex} w="full">
                    <Text>Label: {child.label}</Text>
                    <Text>Href: {child.href}</Text>
                    <Text>SubLabel: {child.subLabel}</Text>
                  </HStack>
                ))}
              </Box>
            )}
          </VStack>
        ))}
      </VStack>
    </Box>
  );
};

export default ManageNavItems;
