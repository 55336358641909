// src/admin/UserManagement.js
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Select,
  IconButton,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;
// Validation schema
const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().optional(),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  isAdmin: yup.boolean().optional(),
  accountType: yup.string().required('Account type is required')
});

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(`${API_BASE_URL}api/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error('Failed to fetch users');
      }
    };

    fetchUsers();
  }, []);

  const onSubmit = async (data) => {
    if (editMode) {
      // Update user
      const response = await fetch(`${API_BASE_URL}api/users/${currentUser._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const updatedUser = await response.json();
        setUsers(users.map(user => (user._id === currentUser._id ? updatedUser : user)));
        setEditMode(false);
        setCurrentUser(null);
        reset();
      } else {
        console.error('Failed to update user');
      }
    } else {
      // Create new user
      const response = await fetch(`${API_BASE_URL}api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const newUser = await response.json();
        setUsers([...users, newUser]);
        reset();
      } else {
        console.error('Failed to create user');
      }
    }
  };

  const handleEdit = (user) => {
    setEditMode(true);
    setCurrentUser(user);
    setValue('name', user.name);
    setValue('email', user.email);
    setValue('phone', user.phone);
    setValue('isAdmin', user.isAdmin);
    setValue('accountType', user.accountType);
  };

  const handleDelete = async (userId) => {
    const response = await fetch(`${API_BASE_URL}api/users/${userId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      setUsers(users.filter(user => user._id !== userId));
    } else {
      console.error('Failed to delete user');
    }
  };

  return (
    <Box>
      <Heading mb={4}>Controle de Usuários</Heading>

      <Box mb={8}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel htmlFor="name">Nome</FormLabel>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => <Input {...field} id="name" />}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => <Input {...field} id="email" />}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="phone">Telefone</FormLabel>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => <Input {...field} id="phone" />}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="password">Senha</FormLabel>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => <Input {...field} id="password" type="password" />}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="isAdmin">Admin</FormLabel>
              <Controller
                name="isAdmin"
                control={control}
                defaultValue={false}
                render={({ field }) => <Checkbox {...field} id="isAdmin" />}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="accountType">Tipo de Permissão</FormLabel>
              <Controller
                name="accountType"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} id="accountType">
                    <option value="user">Usuário Comum</option>
                    <option value="seller">Vendedor</option>
                    <option value="manager">Gerente de Vendas</option>
                    <option value="admin">Admin</option>
                  </Select>
                )}
              />
            </FormControl>

            <Button type="submit" colorScheme="blue">
              {editMode ? 'Atualizar' : 'Criar'}
            </Button>
          </Stack>
        </form>
      </Box>

      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Email</Th>
            <Th>Telefone</Th>
            <Th>Admin</Th>
            <Th>Tipo de Permissão</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map(user => (
            <Tr key={user._id}>
              <Td>{user.name}</Td>
              <Td>{user.email}</Td>
              <Td>{user.phone}</Td>
              <Td>{user.isAdmin ? 'Yes' : 'No'}</Td>
              <Td>{user.accountType}</Td>
              <Td>
                <IconButton
                    icon={<EditIcon />}
                    mr={2}
                    colorScheme="blue"
                    onClick={() => handleEdit(user)}
                />
                <IconButton colorScheme="red"
                  icon={<DeleteIcon />}                  
                  onClick={() => handleDelete(user._id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default UserManagement;
