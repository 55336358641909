import React, { useState, useEffect } from 'react';
import {
  Flex, Button, useTheme, extendTheme, Heading, Spacer, Menu, MenuButton, MenuList, MenuItem, Box
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ChromePicker } from 'react-color';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ThemeSwitcher = ({ setCustomTheme }) => {
  const theme = useTheme();
  const [newColor, setNewColor] = useState({ hex: (theme.colors?.brand && theme.colors.brand[500]) || '#3182CE' });
  const [newTextColor, setNewTextColor] = useState({ hex: (theme.colors?.text && theme.colors.text.default) || '#000000' });
  const [newFont, setNewFont] = useState('Arial');
  const [selectedFont, setSelectedFont] = useState('Arial');

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/theme/get`);
        const data = await response.json();
        if (data && data.colors && data.fonts) {
          setNewColor({ hex: data.colors.brand[500] });
          setNewTextColor({ hex: data.colors.text.default });
          setNewFont(data.fonts.body);
          setSelectedFont(data.fonts.body);
        }
      } catch (error) {
        console.error('Failed to fetch theme:', error);
      }
    };

    fetchTheme();
  }, []);

  const handleColorChange = (color) => {
    setNewColor({ hex: color.hex });
  };

  const handleTextColorChange = (color) => {
    setNewTextColor({ hex: color.hex });
  };

  const handleFontChange = (font) => {
    setNewFont(font);
    setSelectedFont(font);
  };

  const handleSubmitThemeChange = async () => {
    const newTheme = extendTheme({
      ...theme,
      colors: {
        ...theme.colors,
        brand: {
          50: newColor.hex,
          100: newColor.hex,
          200: newColor.hex,
          300: newColor.hex,
          400: newColor.hex,
          500: newColor.hex,
          600: newColor.hex,
          700: newColor.hex,
          800: newColor.hex,
          900: newColor.hex,
        },
        text: {
          default: newTextColor.hex,
        },
      },
      fonts: {
        heading: newFont,
        body: newFont,
      },
    });

    try {
      const response = await fetch(`${API_BASE_URL}api/theme`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ colors: newTheme.colors, fonts: newTheme.fonts }),
      });

      if (!response.ok) {
        throw new Error('Failed to update theme');
      }

      setCustomTheme(newTheme);
    } catch (error) {
      console.error('Failed to update theme:', error);
    }
  };

  const alphabetSample = "ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz";
  const fontOptions = [
    { value: 'Arial', label: 'Arial' },
    { value: 'Verdana', label: 'Verdana' },
    { value: 'Helvetica', label: 'Helvetica' },
    { value: 'Times New Roman', label: 'Times New Roman' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Courier New', label: 'Courier New' },
    { value: 'Brush Script MT', label: 'Brush Script MT' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Goldbill', label: 'Goldbill' }, // Added Goldbill font
  ];

  return (
    <Flex p={4} borderRadius="md">
      <Box align={"center"}>
        <Heading size="md" mb={4}>Controle da Cor Tema do Site</Heading>
        <ChromePicker color={newColor.hex} onChange={handleColorChange} />
        <Button align={"right"} mt={4} colorScheme="teal" onClick={handleSubmitThemeChange}>
          Salvar Cor Tema
        </Button>
      </Box>
      <Spacer my={8} />
      <Box align={"center"}>
        <Heading size="md" mb={4}>Controle de Cor das Letras</Heading>
        <ChromePicker color={newTextColor.hex} onChange={handleTextColorChange} />
        <Button mt={4} colorScheme="teal" onClick={handleSubmitThemeChange}>
          Salvar Cor de Letras
        </Button>
      </Box>
      <Spacer my={8} />
      <Box align={"center"}>
        <Heading size="md" mb={4}>Seleção de Fonte das Letras</Heading>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} style={{ fontFamily: selectedFont, fontSize: '20px', height: '60px', width: '300px', padding: '10px' }}>
            {selectedFont}
          </MenuButton>
          <MenuList maxHeight="300px" overflowY="auto">
            {fontOptions.map((font) => (
              <MenuItem key={font.value} onClick={() => handleFontChange(font.value)} style={{ fontFamily: font.value, fontSize: '16px' }}>
                {font.label}: 
                <Box style={{ fontWeight: 'normal', fontSize: '20px', fontFamily: font.value }}>
                  {alphabetSample}
                </Box>
                <Box style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: font.value }}>
                  {alphabetSample}
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Spacer />
        <Button mt={4} colorScheme="teal" onClick={handleSubmitThemeChange}>
          Salvar Fonte
        </Button>
      </Box>
    </Flex>
  );
};

export default ThemeSwitcher;
