import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Button,
  Input,
} from '@chakra-ui/react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, Tooltip, XAxis, YAxis, Legend, ResponsiveContainer } from 'recharts';
import HeatMap from '../HeatMap/HeatMap';  // Make sure to adjust the import path accordingly

const initialData = {
  orders: 7,
  delivery: 4,
  pickup: 3,
  revenue: 2490.82,
  avgTicket: 355.83,
  canceled: 0,
  currentPeriod: [
    { day: 'Domingo', orders: 0, revenue: 0 },
    { day: 'Segunda', orders: 5, revenue: 1800 },
    { day: 'Terça', orders: 1, revenue: 200 },
    { day: 'Quarta', orders: 0, revenue: 0 },
    { day: 'Quinta', orders: 0, revenue: 0 },
    { day: 'Sexta', orders: 0, revenue: 0 },
    { day: 'Sábado', orders: 1, revenue: 490.82 },
  ],
  previousPeriod: [
    { day: 'Domingo', orders: 0, revenue: 0 },
    { day: 'Segunda', orders: 0, revenue: 0 },
    { day: 'Terça', orders: 1, revenue: 100 },
    { day: 'Quarta', orders: 1, revenue: 100 },
    { day: 'Quinta', orders: 0, revenue: 0 },
    { day: 'Sexta', orders: 0, revenue: 0 },
    { day: 'Sábado', orders: 0, revenue: 0 },
  ],
  customerSegments: [
    { name: 'Novo', value: 50 },
    { name: 'Retornando', value: 50 },
  ],
  orderSources: [
    { name: 'Desktop', value: 20 },
    { name: 'Android', value: 30 },
    { name: 'iPhone', value: 50 },
  ],
  paymentMethods: [
    { name: 'Online', value: 40 },
    { name: 'Dinheiro', value: 30 },
    { name: 'Cartão', value: 30 },
  ],
  heatMapData: [
    { lat: -23.550520, lng: -46.633308, weight: 1 },
    { lat: -23.552520, lng: -46.633308, weight: 1 },
    // Add more heat map data points as needed
  ],
};

const BI_Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(initialData);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchData = (start, end) => {
    // Replace with actual API call
    // Example:
    // fetch(`api_endpoint?start=${start}&end=${end}`)
    //   .then(response => response.json())
    //   .then(data => {
    //     setDashboardData(data);
    //   })
    //   .catch(error => console.error(error));
  };

  useEffect(() => {
    // Initial data fetch
    fetchData(startDate, endDate);
  }, []);

  const handleApply = () => {
    if (startDate && endDate) {
      fetchData(startDate, endDate);
    }
  };


  return (
    <Box p={4}>
      <Flex mb={4} justify="space-between" align="center">
        <Input
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <Input
          type="date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
        <Button onClick={handleApply} colorScheme="teal">Filtrar</Button>
      </Flex>

      <SimpleGrid columns={4} spacing={4} mb={4}>
        <Stat>
          <StatLabel>Total de Pedidos</StatLabel>
          <StatNumber>{dashboardData.orders}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Delivery</StatLabel>
          <StatNumber>{dashboardData.delivery}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Retirada</StatLabel>
          <StatNumber>{dashboardData.pickup}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Faturado</StatLabel>
          <StatNumber>R$ {dashboardData.revenue.toFixed(2)}</StatNumber>
        </Stat>
      </SimpleGrid>

      <SimpleGrid columns={2} spacing={4} mb={4}>
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Text mb={2}>Faturamento por dia da semana</Text>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={dashboardData.currentPeriod}>
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Text mb={2}>Pedidos por dia da semana</Text>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={dashboardData.currentPeriod}>
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="orders" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={3} spacing={4} mb={4}>
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Text mb={2}>Clientes recorrentes</Text>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie data={dashboardData.customerSegments} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8">
                {dashboardData.customerSegments.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={index === 0 ? '#8884d8' : '#82ca9d'} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Text mb={2}>Origem dos pedidos</Text>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie data={dashboardData.orderSources} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#82ca9d">
                {dashboardData.orderSources.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={index === 0 ? '#8884d8' : index === 1 ? '#82ca9d' : '#ffc658'} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Text mb={2}>Formas de pagamento</Text>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie data={dashboardData.paymentMethods} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#ffc658">
                {dashboardData.paymentMethods.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={index === 0 ? '#8884d8' : index === 1 ? '#82ca9d' : '#ffc658'} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </SimpleGrid>

      <Box borderWidth={1} borderRadius="lg" p={4}>
        <Text mb={2}>Mapa de calor dos pedidos</Text>
        <HeatMap data={dashboardData.heatMapData} />
      </Box>

      <Box borderWidth={1} borderRadius="lg" p={4} mt={4}>
        <Text mb={2}>Produtos vendidos no período</Text>
        {/* Insert table component */}
      </Box>
    </Box>
  );
};

export default BI_Dashboard;
