import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Heading,
  Spinner,
} from '@chakra-ui/react';
import ProductCard from './ProductCard';
import { useParams } from 'react-router-dom';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const RelatedProductsPage = () => {
  const { carouselItemId } = useParams();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/uppercarousel/related-products/${carouselItemId}`);
        const data = await response.json();
        setRelatedProducts(data.reverse());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching related products:', error);
        setLoading(false);
      }
    };

    fetchRelatedProducts();
  }, [carouselItemId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" mb={4} />
      </Box>
    );
  }

  return (
    <Flex 
      maxW="container.lg" 
      mr="auto" 
      ml="auto" 
      mb={4} 
      direction="column" 
      alignItems="center" 
      justifyContent="center"
    >
      <Heading as="h1" size="lg" mr={120} mt={2} mb={4}>
        Produtos Relacionados
      </Heading>
      <SimpleGrid columns={[1, 2, 3]} spacing={4} width="100%">
        {relatedProducts.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default RelatedProductsPage;
