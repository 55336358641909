// LineChartData.js

const dayData = {
    labels: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'],
    datasets: [
      {
        label: 'Sul',
        data: [300, 200, 300, 400, 350, 300, 500],
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
      {
        label: 'Centro',
        data: [200, 100, 200, 300, 250, 200, 400],
        borderColor: 'rgba(153,102,255,1)',
        backgroundColor: 'rgba(153,102,255,0.2)',
      },
      {
        label: 'Norte',
        data: [100, 50, 100, 200, 150, 100, 300],
        borderColor: 'rgba(255,159,64,1)',
        backgroundColor: 'rgba(255,159,64,0.2)',
      },
    ],
  };
  
  const weekData = {
    labels: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
    datasets: [
      {
        label: 'Sul',
        data: [1000, 1200, 900, 1400],
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
      {
        label: 'Centro',
        data: [800, 950, 700, 1200],
        borderColor: 'rgba(153,102,255,1)',
        backgroundColor: 'rgba(153,102,255,0.2)',
      },
      {
        label: 'Norte',
        data: [600, 700, 500, 900],
        borderColor: 'rgba(255,159,64,1)',
        backgroundColor: 'rgba(255,159,64,0.2)',
      },
    ],
  };
  
  const monthData = {
    labels: Array.from({ length: 30 }, (_, i) => `Dia ${i + 1}`),
    datasets: [
      {
        label: 'Sul',
        data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 500 + 100)),
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
      {
        label: 'Centro',
        data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 400 + 100)),
        borderColor: 'rgba(153,102,255,1)',
        backgroundColor: 'rgba(153,102,255,0.2)',
      },
      {
        label: 'Norte',
        data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 300 + 50)),
        borderColor: 'rgba(255,159,64,1)',
        backgroundColor: 'rgba(255,159,64,0.2)',
      },
    ],
  };
  
  export { dayData, weekData, monthData };
  