import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Stack,
  useToast,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import './FirstOrderList.css'; // Import the CSS file
import { FaCheck, FaTimes } from 'react-icons/fa';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const FirstOrderList = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchFirstOrders = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/orders/first`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setOrders(data.reverse() || []);
        } else {
          console.error('Failed to fetch first orders');
        }
      } catch (error) {
        console.error('Error fetching first orders:', error);
      }
    };

    fetchFirstOrders();
  }, []);

  const handleUpdateStatus = async (orderId, status) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/orders/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ status }),
      });

      if (response.ok) {
        const updatedOrder = await response.json();
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order._id === orderId ? { ...order, status: updatedOrder.status } : order
          )
        );
        toast({
          title: 'Status updated',
          description: 'Order status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Failed to update order status');
        toast({
          title: 'Error',
          description: 'Failed to update order status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update order status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <Box p={1} className="first-order-list-container">
      <Heading mb={0} className="first-order-heading">Primeiro Pedido da Conta</Heading>
      <Table variant="striped" className="first-order-table">
        <Thead>
          <Tr>
            <Th>Pedido</Th>
            <Th>Status</Th>
            <Th>Data</Th>
            <Th>Cliente</Th>
            <Th>Telefone</Th>
            <Th>Email</Th>
            <Th>Pagamento</Th>
            <Th>Total</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map(order => (
            <Tr key={order._id}>
              <Td>
                <Button variant="link" colorScheme="blue" onClick={() => handleOpenModal(order)}>
                  #{order._id}
                </Button>
              </Td>
              <Td>{order.status}</Td>
              <Td>{new Date(order.createdAt).toLocaleString()}</Td>
              <Td>{order.userId && order.userId.name ? order.userId.name : ''}</Td>
              <Td>{order.userId && order.userId.phone ? order.userId.phone : ''}</Td>
              <Td>{order.userId && order.userId.email ? order.userId.email : ''}</Td>
              <Td>{order.paymentMethod || ''}</Td>
              <Td>R${order.items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}</Td>
              <Td>
                <Stack direction="row" spacing={4}>
                  {(order.status === 'pendente' || order.status === 'aguardando_aval_vendedor') && (
                    <>
                      <Button size="sm" className="approve-button" onClick={() => handleUpdateStatus(order._id, 'aguardando_aval_gestor')}>
                        <FaCheck/>
                      </Button>
                      <Button size="sm" className="reject-button" onClick={() => handleUpdateStatus(order._id, 'rejeitado_vendedor')}>
                        <FaTimes />
                      </Button>
                    </>
                  )}
                </Stack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Pedido</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedOrder && (
              <Box>
                <Text><strong>Pedido ID:</strong> {selectedOrder._id}</Text>
                <Text><strong>Status:</strong> {selectedOrder.status}</Text>
                <Text><strong>Data do pedido:</strong> {new Date(selectedOrder.createdAt).toLocaleString()}</Text>
                <Text><strong>Cliente:</strong> {selectedOrder.userId ? selectedOrder.userId.name || '' : ''}</Text>
                <Text><strong>Telefone:</strong> {selectedOrder.userId ? selectedOrder.userId.phone || '' : ''}</Text>
                <Text><strong>Email:</strong> {selectedOrder.userId ? selectedOrder.userId.email || '' : ''}</Text>
                <Text><strong>Endereço:</strong> {selectedOrder.shippingAddress.endereco}, {selectedOrder.shippingAddress.numero}, {selectedOrder.shippingAddress.complemento}, {selectedOrder.shippingAddress.bairro}, {selectedOrder.shippingAddress.cidade}, {selectedOrder.shippingAddress.estado}, {selectedOrder.shippingAddress.pais}</Text>
                <Text><strong>CEP:</strong> {selectedOrder.shippingAddress.cep}</Text>
                <Text><strong>Valor Total dos itens da Compra:</strong> R${selectedOrder.items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}</Text>
                <Text><strong>Valor do frete:</strong> R${selectedOrder.shippingCost.toFixed(2)}</Text>
                <Text><strong>Notas:</strong> {selectedOrder.notes}</Text>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Produto</Th>
                      <Th>Cor</Th>
                      <Th>Tamanho</Th>
                      <Th>Quantidade</Th>
                      <Th>Preço Unidade</Th>
                      <Th>Preço Total</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedOrder.items.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.product.name}</Td>
                        <Td>
                          <Flex alignItems="center">
                            <Box
                              bg={item.color.hex}
                              w="20px"
                              h="20px"
                              borderRadius="full"
                              ml={2}
                              mr={2}
                              border="1px solid gray"
                            />
                            <Text>{item.color.name}</Text>
                          </Flex>
                        </Td>
                        <Td>{item.size.name}</Td>
                        <Td>{item.quantity}</Td>
                        <Td>R${item.price.toFixed(2)}</Td>
                        <Td>R${(item.price * item.quantity).toFixed(2)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Button colorScheme="blue" mt={4} onClick={() => setIsShippingModalOpen(true)}>Calcular Frete</Button>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FirstOrderList;
