import React, { useState, useEffect, useRef } from 'react';
import {
  Flex,
  Box,
  InputGroup,
  InputRightElement,
  Input,
  IconButton,
  Image,
  useTheme,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  Text,
  Stack,
  Collapse,
  useDisclosure,
  Divider,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { SearchIcon, ChatIcon, HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FaUserAlt, FaShoppingCart } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../User/UserContext';
import FixedBottomNavbar from './FixedBottomNavbar';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

// Function to convert hex to RGB
const hexToRgb = (hex) => {
  let r = 0, g = 0, b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return [r, g, b];
};

// Function to calculate luminance
const getLuminance = (r, g, b) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

// NavBar-specific constants
const fontSize = '1.20rem'; // Font size (relative)
const navItemSpacing = '2rem'; // Spacing between nav items (30px converted to rem)
const edgeSpacing = '9.2rem'; // Spacing at the edges of the navbar (150px converted to rem)

const UnifiedComponent = () => {
  const theme = useTheme();
  const brandColor = theme.colors?.brand?.[500] || 'blue.500';
  const [iconColor, setIconColor] = useState('white');
  const textColor = theme.colors?.text?.default || 'black';
  const { user, setUser } = useUser();
  const [searchQuery, setSearchQuery] = useState('');
  const [contacts, setContacts] = useState({
    whatsapp: 'Loading...',
    email: 'Loading...',
  });
  const [logoUrl, setLogoUrl] = useState(null);
  const [navItems, setNavItems] = useState([]);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [showFixedNavbar, setShowFixedNavbar] = useState(false);
  const navbarRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Calculate luminance and set icon color
    const [r, g, b] = hexToRgb(brandColor);
    const luminance = getLuminance(r, g, b);
    setIconColor(luminance > 0.5 ? 'black' : 'white');

    fetchUserData();
    fetchContacts();
    fetchLogo();
    fetchNavItems();
    setupIntersectionObserver();
  }, [brandColor]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpen, onClose]);

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch(`${API_BASE_URL}api/users/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/contacts`);
      const data = await response.json();
      setContacts(data);
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };

  const fetchLogo = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/logo?type=superior`);
      const data = await response.json();
      setLogoUrl(`data:image/png;base64,${data.base64Data}`);
    } catch (error) {
      console.error('Failed to fetch header logo:', error);
    }
  };

  const fetchNavItems = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/navitems`);
      const data = await response.json();
      setNavItems(data);
    } catch (error) {
      console.error('Error fetching nav items:', error);
    }
  };

  const setupIntersectionObserver = () => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowFixedNavbar(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    );

    if (navbarRef.current) {
      observer.observe(navbarRef.current);
    }

    return () => {
      if (navbarRef.current) {
        observer.unobserve(navbarRef.current);
      }
    };
  };

  const handleScroll = () => {
    if (isOpen) {
      onClose();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  };

  const handleAccountClick = () => {
    if (user?.isAdmin) {
      navigate('/admin');
    } else {
      navigate('/account');
    }
  };

  const handleSearch = () => {
    navigate(`/search?query=${searchQuery}`);
    setSearchQuery('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        bg={brandColor}
        p={3}
        alignItems="center"
        mb={0}
        ref={navbarRef}
        justifyContent="space-between"
        px={{ base: 4, md: 140 }} // Added padding to left and right
      >
        <Box display="flex" alignItems="center" flexShrink={0} px={4} mb={{ base: 2, md: 0 }}>
          <Link to="/" onClick={() => setSearchQuery('')}>
            {logoUrl && <Image src={logoUrl} alt="Home / Cliente LOGO" height="100px" />}
          </Link>
        </Box>

        <Box flex="1" display="flex" justifyContent="center" px={4} py={0}>
          <InputGroup maxWidth="800px" width="100%">
            <Input
              type="text"
              placeholder="O que você está buscando?"
              borderRadius="full"
              bg="white"
              color="black"
              _placeholder={{ color: 'olive' }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                bg="transparent"
                color="black"
                _hover={{ bg: 'transparent' }}
                onClick={handleSearch}
              />
            </InputRightElement>
          </InputGroup>
        </Box>

        <Flex alignItems="center" color={iconColor} mb={{ base: -3, md: 0 }}>
  <Popover trigger="hover">
    <PopoverTrigger>
      <IconButton aria-label="Chat" icon={<ChatIcon />} bg="transparent" color={iconColor} _hover={{ bg: 'transparent' }} mx={2} />
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody>
        <Button as="a" href={`mailto:${contacts.email}`} variant="ghost" width="100%">{contacts.email}</Button>
        <Button as="a" href={`https://wa.me/${contacts.whatsapp}`} variant="ghost" width="100%" mt={2}>WhatsApp Chat</Button>
      </PopoverBody>
    </PopoverContent>
  </Popover>
  <Popover trigger="hover">
    <PopoverTrigger>
      <IconButton aria-label="User Account" icon={<FaUserAlt />} bg="transparent" color={iconColor} _hover={{ bg: 'transparent' }} mx={2} />
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody>
        {user ? (
          <>
            <Text mb={2} color="black" align={"center"} fontSize="xl" fontWeight="bold">
              Olá, {user.name ? user.name : 'Usuário'}!
            </Text>
            <Button onClick={handleAccountClick} variant="ghost" width="100%">Minha conta</Button>
            <Button onClick={handleLogout} variant="ghost" width="100%" mt={2}>Sair</Button>
          </>
        ) : (
          <>
            <Button as={Link} to="/register" variant="ghost" width="100%">Criar uma conta</Button>
            <Button as={Link} to="/login" variant="ghost" width="100%" mt={2}>Iniciar sessão</Button>
          </>
        )}
      </PopoverBody>
    </PopoverContent>
  </Popover>
  <IconButton as={Link} to="/cart" aria-label="Shopping Cart" icon={<FaShoppingCart />} bg="transparent" color={iconColor} _hover={{ bg: 'transparent' }} mx={2} />
</Flex>

      </Flex>

      <Flex
        bg={brandColor}
        color={textColor}
        minH={'40px'}
        py={{ base: 0 }}
        px={{ base: 4 }}
        borderBottom={0}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        display={{ base: 'none', md: 'flex' }} // Hide on mobile
      >
        <Flex flex={{ base: 1, md: 'none' }} pt={0} px={0} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
          <IconButton onClick={onToggle} icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={'ghost'} aria-label={'Toggle Navigation'} />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'space-between' }} align="center" w="100%" px={edgeSpacing}>
          <Flex display={{ base: 'none', md: 'flex' }} w="100%" justify="space-between" align="center" mx={navItemSpacing}>
            <DesktopNav isAdmin={user?.isAdmin} navItems={navItems} textColor={textColor} />
          </Flex>
        </Flex>
      </Flex>
      <Box position="relative">
        <Collapse in={isOpen} animateOpacity>
          <MobileNav isAdmin={user?.isAdmin} navItems={navItems} textColor={textColor} showFixedNavbar={showFixedNavbar} />
        </Collapse>
      </Box>
      {showFixedNavbar && <FixedBottomNavbar onToggle={onToggle} isOpen={isOpen} />}
    </Box>
  );
};

const DesktopNav = ({ isAdmin, navItems, textColor }) => {
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  const filteredNavItems = isAdmin ? navItems : navItems.filter(item => item.label !== 'Area do Admin');

  return (
    <Flex direction={'row'} justify={'space-between'} align="center" w="100%">
      {filteredNavItems.map((navItem, index) => (
        <React.Fragment key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box as={Link}
                to={navItem.href ?? '#'}
                p={2}
                mx={navItemSpacing}
                fontSize={fontSize}
                fontWeight={'bold'}
                color={textColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Box>
            </PopoverTrigger>
            {navItem.children && (
              <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
          {index < filteredNavItems.length - 1 && (
            <Divider orientation="vertical" height="24px" borderColor="whiteAlpha.600" />
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  const theme = useTheme();
  const brandColor = theme.colors?.brand?.[500] || 'blue.500';
  return (
    <Link to={href} role={'group'} display={'block'} p={2} rounded={'md'} _hover={{ bg: brandColor }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text transition={'all .3s ease'} _groupHover={{ color: 'pink.400' }} fontWeight={500} color="black" fontSize={fontSize}>
            {label}
          </Text>
          <Text fontSize={'sm'} color="black">{subLabel}</Text>
        </Box>
        <Flex transition={'all .3s ease'} transform={'translateX(-10px)'} opacity={0} _groupHover={{ opacity: '100%', transform: 'translateX(0)' }} justify={'flex-end'} align={'center'} flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = ({ isAdmin, navItems, textColor, showFixedNavbar }) => {
  const theme = useTheme();
  const brandColor = theme.colors?.brand?.[500] || 'blue.500';
  const bgColor = brandColor;
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const navRef = useRef(null);

  useEffect(() => {
    if (navRef.current) {
      navRef.current.style.top = showFixedNavbar ? '0px' : '150px';
    }
  }, [showFixedNavbar]);

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current) {
        navRef.current.style.top = showFixedNavbar ? '0px' : '60px';
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [showFixedNavbar]);

  const filteredNavItems = isAdmin ? navItems : navItems.filter(item => item.label !== 'Area do Admin');

  return (
    <Stack
      ref={navRef}
      bg={bgColor}
      p={4}
      display={{ md: 'none' }}
      spacing={4}
      position="fixed"
      left={0}
      width="100%"
      zIndex="999"
    >
      {filteredNavItems.map((navItem) => (
        <MobileNavItem key={navItem.label} label={navItem.label} subLabel={navItem.subLabel} children={navItem.children} href={navItem.href} textColor={textColor} borderColor={borderColor} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, subLabel, children, href, textColor, borderColor }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex py={2} as={Link} to={href ?? '#'} justify={'space-between'} align={'center'} _hover={{ textDecoration: 'none' }}>
        <Box>
          <Text fontWeight={'bold'} color={textColor} fontSize={fontSize}>
            {label}
          </Text>
          {subLabel && <Text fontSize='sm' color="black">{subLabel}</Text>}
        </Box>
        {children && (
          <Icon as={ChevronDownIcon} transition={'all .25s ease-in-out'} transform={isOpen ? 'rotate(180deg)' : ''} w={6} h={6} />
        )}
      </Flex>
      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack mt={2} pl={4} borderLeft={1} borderStyle={'solid'} borderColor={borderColor} align={'start'} spacing={2}>
          {children && children.map((child) => (
            <Box key={child.label}>
              <Link to={child.href} py={2} color="black" fontSize={fontSize}>
                {child.label}
              </Link>
              {child.subLabel && <Text fontSize='sm' color="black" pl={4}>{child.subLabel}</Text>}
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default UnifiedComponent;
