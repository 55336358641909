import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Textarea,
  useToast,
  Flex,
  Spacer,
  Text,
  Select,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../User/UserContext';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const CheckoutPage = () => {
  const { cart, setCart } = useUser();
  const [formData, setFormData] = useState({
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    pais: 'Brasil',
    notes: '',
    type: '' // default to 'immediate'
  });
  const navigate = useNavigate();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const checkCEP = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      if (!response.ok) {
        throw new Error('Failed to fetch CEP data');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching CEP data:', error);
      return null;
    }
  };

  const handleCEPBlur = async (e) => {
    const cep = e.target.value.replace(/\D/g, '');
    if (cep.length === 8) {
      const data = await checkCEP(cep);
      if (data && !data.erro) {
        setFormData({
          ...formData,
          cep,
          endereco: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          estado: data.uf,
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePlaceOrder = async () => {
    const transformedCart = cart.map(item => ({
      productId: item.productId,
      productPrice: item.productPrice ? item.productPrice: null,
      colorId: item.color ? item.color._id : null,
      sizeId: item.size ? item.size._id : null,
      sizeName: item.size ? item.size.name : null,
      quantity: item.quantity,
      price: item.price,
      budgetOn: item.budgetOn
    }));

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}api/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          items: transformedCart,
          shippingAddress: formData,
          notes: formData.notes,
          type: formData.type, // use the selected type
        }),
      });

      if (response.ok) {
        setCart([]);
        toast({
          title: 'Order placed',
          description: 'Your order has been placed successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/');
      } else {
        throw new Error('Failed to place order');
      }
    } catch (error) {
      console.error('Error placing order:', error);
      toast({
        title: 'Error',
        description: 'Failed to place order.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const totalQuantity = cart.reduce((acc, item) => acc + item.quantity, 0);
  const totalValue = cart.reduce((acc, item) => acc + item.quantity * (item.budgetOn ? 0 : item.price), 0).toFixed(2);

  return (
    <Box p={4} maxWidth={{ base: '720px', md: 'none' }} mx="auto">
      <Heading mb={4}>Finalizar Pedido</Heading>
      <Box mb={4}>
        <Text fontSize="xl" fontWeight="bold">Total de Itens: {totalQuantity}</Text>
        <Text fontSize="xl" fontWeight="bold">Valor Total: R${totalValue}</Text>
      </Box>
      <Stack spacing={4}>
        <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 4, md: 0 }} mt={2}>
          <FormControl isRequired>
            <FormLabel fontWeight={"bold"}>CEP</FormLabel>
            <Input
              name="cep"
              value={formData.cep}
              onChange={handleChange}
              onBlur={handleCEPBlur}
              placeholder="xxxxx-xxx"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </FormControl>
          <Spacer />
          <FormControl isRequired>
            <FormLabel fontWeight={"bold"}>Endereço</FormLabel>
            <Input
              name="endereco"
              value={formData.endereco}
              onChange={handleChange}
              placeholder="Endereço"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </FormControl>
          <Spacer />
          <FormControl isRequired>
            <FormLabel fontWeight={"bold"}>Número</FormLabel>
            <Input
              name="numero"
              value={formData.numero}
              onChange={handleChange}
              placeholder="Número"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </FormControl>
        </Flex>
        <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 4, md: 4 }}>
          <FormControl>
            <FormLabel fontWeight={"bold"}>Complemento</FormLabel>
            <Input
              name="complemento"
              value={formData.complemento}
              onChange={handleChange}
              placeholder="Complemento"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontWeight={"bold"}>Bairro</FormLabel>
            <Input
              name="bairro"
              value={formData.bairro}
              onChange={handleChange}
              placeholder="Bairro"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontWeight={"bold"}>Cidade</FormLabel>
            <Input
              name="cidade"
              value={formData.cidade}
              onChange={handleChange}
              placeholder="Cidade"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontWeight={"bold"}>Estado</FormLabel>
            <Input
              name="estado"
              value={formData.estado}
              onChange={handleChange}
              placeholder="Estado"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontWeight={"bold"}>País</FormLabel>
            <Input
              name="pais"
              value={formData.pais}
              onChange={handleChange}
              placeholder="País"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </FormControl>
        </Flex>
        <FormControl isRequired>
          <FormLabel fontWeight={"bold"}>Tipo de Pedido</FormLabel>
          <Select
            placeholder="Selecione uma opção"
            name="type"
            value={formData.type}
            onChange={handleChange}
          >
            <option value="imediato">Imediato</option>
            <option value="reserva">Reserva</option>
          </Select>
        </FormControl>
        <FormControl id="notes">
          <FormLabel fontWeight={"bold"}>Notas Adicionais</FormLabel>
          <Textarea
            placeholder="Alguma informação adicional?"
            value={formData.notes}
            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
            whiteSpace="pre-wrap"
            wordBreak="break-word"
          />
        </FormControl>
        <Button colorScheme="green" onClick={handlePlaceOrder} width="100%">
          Fazer Pedido
        </Button>
      </Stack>
    </Box>
  );
};

export default CheckoutPage;
