// transactionMockData.js

const transactionMockData = [
    { id: '01e4dsa', product: 'Produto A', quantity: 30, volume: 'R$300' },
    { id: '0315dsaa', product: 'Produto B', quantity: 20, volume: 'R$200' },
    { id: '01e4dsa', product: 'Produto C', quantity: 50, volume: 'R$500' },
    { id: '51034szv', product: 'Produto D', quantity: 10, volume: 'R$100' },
    { id: '789sadas', product: 'Produto E', quantity: 70, volume: 'R$700' },
    { id: '98fdsa32', product: 'Produto F', quantity: 40, volume: 'R$400' },
    { id: '21dfas43', product: 'Produto G', quantity: 90, volume: 'R$900' },
    { id: 'af12dsaf', product: 'Produto H', quantity: 25, volume: 'R$250' },
    { id: 'fa34232f', product: 'Produto I', quantity: 15, volume: 'R$150' },
    { id: 'vbn43434', product: 'Produto J', quantity: 55, volume: 'R$550' },
    { id: 'rewr3245', product: 'Produto K', quantity: 65, volume: 'R$650' },
    { id: 'erwre324', product: 'Produto L', quantity: 35, volume: 'R$350' },
    // Add more entries as needed
  ];
  
  export default transactionMockData;
  