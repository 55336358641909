// StackedBarChart.js

import React, { useState, useEffect } from 'react';
import { Box, Select, Button, Flex } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import sellerMockData from './MockData';

// Register the required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const prepareData = (timeRange, sortType) => {
  let labels = [];
  let datasets = [];

  const sellers = [...new Set(sellerMockData.map(data => data.seller))];

  switch (timeRange) {
    case 'Month':
      labels = Array.from({ length: 30 }, (_, i) => `Dia ${i + 1}`);
      break;
    case 'Weeks':
      labels = ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'];
      break;
    case 'Dia':
      labels = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
      break;
    default:
      labels = Array.from({ length: 30 }, (_, i) => `Dia ${i + 1}`);
  }

  sellers.forEach(seller => {
    const data = labels.map(() => Math.floor(Math.random() * 100));
    datasets.push({
      label: seller,
      data,
      backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
      borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
      borderWidth: 1,
    });
  });

  if (sortType === 'name') {
    datasets.sort((a, b) => a.label.localeCompare(b.label));
  } else if (sortType === 'value') {
    datasets.sort((a, b) => {
      const aSeller = sellerMockData.find(data => data.seller === a.label);
      const bSeller = sellerMockData.find(data => data.seller === b.label);
      return bSeller.value - aSeller.value;
    });
  }

  return { labels, datasets };
};

const StackedBarChart = () => {
  const [timeRange, setTimeRange] = useState('Month');
  const [sortType, setSortType] = useState(null);
  const [chartData, setChartData] = useState(prepareData(timeRange, sortType));

  useEffect(() => {
    setChartData(prepareData(timeRange, sortType));
  }, [timeRange, sortType]);

  const data = {
    labels: chartData.labels,
    datasets: chartData.datasets,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'yellow', // x-axis labels color
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: 'yellow', // y-axis labels color
        },
      },
    },
  };

  return (
    <Box bg="gray.700" p={2} borderRadius="md">
      <Flex justify="space-between" mb={5}>
        <Select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          bg="gray.800"
          color="yellow"
          borderColor="blue.500"
          _hover={{ borderColor: 'blue.300' }}
          _focus={{ borderColor: 'blue.300' }}
          sx={{
            option: {
              backgroundColor: 'gray.800',
              color: 'yellow',
            },
          }}
        >
          <option value="Dia">Dia</option>
          <option value="Weeks">Semanas</option>
          <option value="Month">Mês</option>
        </Select>
        <Button colorScheme="teal" onClick={() => setSortType('name')}>Por Nome</Button>
        <Button colorScheme="teal" onClick={() => setSortType('value')}>Por Valor</Button>
      </Flex>
      <Bar data={data} options={options} />
    </Box>
  );
};

export default StackedBarChart;
