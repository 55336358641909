import React, { useEffect } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Input,
  Text,
  VStack,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  FormLabel,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';

const FilterBar = ({
  sizes = [],
  brands = [],
  colors = [],
  genres = [],
  itemTypes = [],
  categories = [],
  selectedFilters,
  onFilterChange,
}) => {

  const handleCheckboxChange = (values, type) => {
    onFilterChange({ [type]: values });
  };

  const toggleSizeSelection = (size) => {
    const newSelectedSizes = new Set(selectedFilters.sizes);
    if (newSelectedSizes.has(size._id)) {
      newSelectedSizes.delete(size._id);
    } else {
      newSelectedSizes.add(size._id);
    }
    onFilterChange({ sizes: newSelectedSizes });
  };

  const handleSliderChange = (value) => {
    onFilterChange({ priceRange: value });
  };

  const handleInputChange = (value, type) => {
    const [minPrice, maxPrice] = selectedFilters.priceRange;
    if (type === 'min') {
      onFilterChange({ priceRange: [Math.min(value, maxPrice), maxPrice] });
    } else {
      onFilterChange({ priceRange: [minPrice, Math.max(value, minPrice)] });
    }
  };

  const handleSortChange = (e) => {
    onFilterChange({ sortOption: e.target.value });
  };

  const headingStyle = {
    color: useColorModeValue('black', 'white'),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
    mb: 2,
  };

  const sectionStyle = {
    mb: 4,
    p: 3,
    bg: useColorModeValue('gray.100', 'gray.700'),
    borderRadius: 'md',
  };

  const checkboxStyle = {
    color: useColorModeValue('black', 'white'),
  };

  return (
    <Box p={4} width="300px" bg={useColorModeValue('white', 'gray.800')} borderRadius="md" boxShadow="lg">
      <VStack align="stretch" spacing={4}>
        <Box {...sectionStyle}>
          <Heading size="md" {...headingStyle}>Ordenar por</Heading>
          <Select
            value={selectedFilters.sortOption}
            onChange={handleSortChange}
            bg={useColorModeValue('white', 'gray.600')}
            borderColor={useColorModeValue('gray.300', 'gray.600')}
            _hover={{ borderColor: useColorModeValue('gray.400', 'gray.500') }}
          >
            <option value="latest-desc">Mais recentes</option>
            <option value="latest-asc">Menos recentes</option>
            <option value="price-asc">Preço: Menor para Maior</option>
            <option value="price-desc">Preço: Maior para Menor</option>
          </Select>
        </Box>
        <Divider />
        <Box {...sectionStyle}>
          <Heading size="md" {...headingStyle}>Gêneros</Heading>
          <CheckboxGroup
            value={selectedFilters.genres}
            onChange={(values) => handleCheckboxChange(values, 'genres')}
          >
            <VStack align="start" spacing={1}>
              {genres.map((genre) => (
                <Checkbox key={genre._id} value={genre._id} {...checkboxStyle}>
                  {genre.name}
                </Checkbox>
              ))}              
            </VStack>
          </CheckboxGroup>
        </Box>
        <Divider />
        <Box {...sectionStyle}>
          <Heading size="md" {...headingStyle}>Tipos de Item</Heading>
          <CheckboxGroup
            value={selectedFilters.itemTypes}
            onChange={(values) => handleCheckboxChange(values, 'itemTypes')}
          >
            <VStack align="start" spacing={1}>
              {itemTypes.map((itemType) => (
                <Checkbox key={itemType._id} value={itemType._id} {...checkboxStyle}>
                  {itemType.name}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
        <Divider />
        <Box {...sectionStyle}>
          <Heading size="md" {...headingStyle}>Categorias</Heading>
          <CheckboxGroup
            value={selectedFilters.categories}
            onChange={(values) => handleCheckboxChange(values, 'categories')}
          >
            <VStack align="start" spacing={1}>
              {categories.map((category) => (
                <Checkbox key={category._id} value={category._id} {...checkboxStyle}>
                  {category.name}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
        <Divider />
        <Box {...sectionStyle}>
          <Heading size="md" {...headingStyle}>Tamanhos</Heading>
          <SimpleGrid columns={5} spacing={2}>
            {sizes.map((size) => (
              <Box
                key={size._id}
                border="1px solid"
                borderRadius="50%"
                p={2}
                textAlign="center"
                cursor="pointer"
                bg={selectedFilters.sizes.has(size._id) ? 'blue.500' : 'white'}
                color={selectedFilters.sizes.has(size._id) ? 'white' : 'black'}
                onClick={() => toggleSizeSelection(size)}
              >
                {size.name}
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Divider />
        <Box {...sectionStyle}>
          <Heading size="md" {...headingStyle}>Marcas</Heading>
          <CheckboxGroup
            value={selectedFilters.brands}
            onChange={(values) => handleCheckboxChange(values, 'brands')}
          >
            <VStack align="start" spacing={1}>
              {brands.map((brand) => (
                <Checkbox key={brand._id} value={brand._id} {...checkboxStyle}>
                  {brand.name}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
        <Divider />
        <Box {...sectionStyle}>
          <Heading size="md" {...headingStyle}>Cores</Heading>
          <CheckboxGroup
            value={selectedFilters.colors}
            onChange={(values) => handleCheckboxChange(values, 'colors')}
          >
            <VStack align="start" spacing={1}>
              {colors.map((color) => (
                <Checkbox key={color._id} value={color._id} {...checkboxStyle}>
                  <HStack>
                    <Box w={4} h={4} bg={color.hex} borderRadius="50%" border="1px solid" />
                    <Text>{color.name}</Text>
                  </HStack>
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
        <Divider />
        <Box {...sectionStyle}>
          <Heading size="md" {...headingStyle}>Preço</Heading>
          <RangeSlider
            aria-label={['min', 'max']}
            defaultValue={[0, 1000]}
            min={0}
            max={1000}
            step={1}
            onChange={handleSliderChange}
            value={selectedFilters.priceRange}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
          </RangeSlider>
          <HStack justifyContent="space-between">
            <Box>
              <FormLabel htmlFor="minPrice">Min</FormLabel>
              <Input
                id="minPrice"
                type="number"
                value={selectedFilters.priceRange[0]}
                onChange={(e) => handleInputChange(Number(e.target.value), 'min')}
                min={0}
                max={selectedFilters.priceRange[1]}
                bg={useColorModeValue('white', 'gray.600')}
                borderColor={useColorModeValue('gray.300', 'gray.600')}
                _hover={{ borderColor: useColorModeValue('gray.400', 'gray.500') }}
              />
            </Box>
            <Box>
              <FormLabel htmlFor="maxPrice">Max</FormLabel>
              <Input
                id="maxPrice"
                type="number"
                value={selectedFilters.priceRange[1]}
                onChange={(e) => handleInputChange(Number(e.target.value), 'max')}
                min={selectedFilters.priceRange[0]}
                max={1000}
                bg={useColorModeValue('white', 'gray.600')}
                borderColor={useColorModeValue('gray.300', 'gray.600')}
                _hover={{ borderColor: useColorModeValue('gray.400', 'gray.500') }}
              />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default FilterBar;
