import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import geoFeatures from './Data/brazil_geo.json'; // Adjust the path accordingly
import mockGeographyData from './Data/mockData'; // Adjust the path accordingly

const GeographyChart = () => {
  const svgRef = useRef();

  useEffect(() => {
    // Set up the SVG element for D3 map
    const svg = d3.select(svgRef.current)
      .attr('width', 1400)
      .attr('height', 800);

    const projection = d3.geoMercator()
      .scale(1000)
      .center([-65, -8]); // Centering the map on Brazil

    const path = d3.geoPath().projection(projection);

    // Create a value map for quick lookup
    const valueMap = new Map();
    mockGeographyData.forEach(d => {
      valueMap.set(d.id, d.value);
    });

    // Define the color scale
    const colorScale = d3.scaleSequential(d3.interpolateBlues)
      .domain([0, d3.max(mockGeographyData, d => d.value)]);

    // Add the map
    svg.selectAll('path')
      .data(geoFeatures.features)
      .enter()
      .append('path')
      .attr('d', path)
      .attr('fill', (d) => {
        // Use the value from the valueMap to determine the color
        const value = valueMap.get(d.id) || 0;
        return colorScale(value);
      })
      .attr('stroke', '#000')
      .attr('stroke-width', 0.5)
      .on('mouseover', function (event, d) {
        // Show tooltip on mouseover
        const value = valueMap.get(d.id) || 0;
        d3.select(this)
          .attr('stroke', '#333')
          .attr('stroke-width', 1.5);
        
        tooltip.transition()
          .duration(200)
          .style('opacity', .9);
        
        tooltip.html(`${d.properties.name}: ${value}`)
          .style('left', (event.pageX + 5) + 'px')
          .style('top', (event.pageY - 28) + 'px');
      })
      .on('mouseout', function () {
        // Hide tooltip on mouseout
        d3.select(this)
          .attr('stroke', '#000')
          .attr('stroke-width', 0.5);
        
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });

    // Add tooltip div
    const tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('text-align', 'center')
      .style('width', '120px')
      .style('height', '28px')
      .style('padding', '2px')
      .style('font', '12px sans-serif')
      .style('background', 'lightsteelblue')
      .style('border', '0px')
      .style('border-radius', '8px')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    // Clean up tooltip on unmount
    return () => tooltip.remove();
  }, []);

  return (
    <div>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default GeographyChart;
