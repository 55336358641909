const mockGeographyData = [
  { id: 'AC', value: 500000 }, // Acre
  { id: 'AL', value: 700000 }, // Alagoas
  { id: 'AM', value: 300000 }, // Amazonas
  { id: 'AP', value: 200000 }, // Amapá
  { id: 'BA', value: 900000 }, // Bahia
  { id: 'CE', value: 600000 }, // Ceará
  { id: 'DF', value: 100000 }, // Distrito Federal
  { id: 'ES', value: 400000 }, // Espírito Santo
  { id: 'GO', value: 800000 }, // Goiás
  { id: 'MA', value: 750000 }, // Maranhão
  { id: 'MG', value: 950000 }, // Minas Gerais
  { id: 'MS', value: 550000 }, // Mato Grosso do Sul
  { id: 'MT', value: 450000 }, // Mato Grosso
  { id: 'PA', value: 650000 }, // Pará
  { id: 'PB', value: 350000 }, // Paraíba
  { id: 'PE', value: 850000 }, // Pernambuco
  { id: 'PI', value: 250000 }, // Piauí
  { id: 'PR', value: 700000 }, // Paraná
  { id: 'RJ', value: 500000 }, // Rio de Janeiro
  { id: 'RN', value: 600000 }, // Rio Grande do Norte
  { id: 'RO', value: 200000 }, // Rondônia
  { id: 'RR', value: 300000 }, // Roraima
  { id: 'RS', value: 900000 }, // Rio Grande do Sul
  { id: 'SC', value: 100000 }, // Santa Catarina
  { id: 'SE', value: 400000 }, // Sergipe
  { id: 'SP', value: 800000 }, // São Paulo
  { id: 'TO', value: 750000 }, // Tocantins
];

export default mockGeographyData;
