import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Heading, Flex, Text, Select } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const ManagePages = () => {
  const [pages, setPages] = useState([]);
  const [footerLinks, setFooterLinks] = useState([]);
  const [currentPage, setCurrentPage] = useState({ title: '', content: '', footerLinkId: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchPages();
    fetchFooterLinks();
    resetForm();
  }, [reload]);

  const fetchPages = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/pages`);
      const data = await response.json();
      setPages(data);
    } catch (error) {
      console.error('Failed to fetch pages:', error);
    }
  };

  const fetchFooterLinks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/footerlinks`);
      const data = await response.json();
      setFooterLinks(data);
    } catch (error) {
      console.error('Failed to fetch footer links:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentPage({ ...currentPage, [name]: value });
  };

  const handleContentChange = (content) => {
    setCurrentPage({ ...currentPage, content });
  };

  const resetForm = () => {
    setCurrentPage({ title: '', content: '', footerLinkId: '' });
  };

  const handleAddPage = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/pages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentPage),
      });

      if (!response.ok) {
        throw new Error('Failed to add page');
      }

      resetForm();
      setReload(!reload);
    } catch (error) {
      console.error('Error adding page:', error);
    }
  };

  const handleUpdatePage = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/pages/${editingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentPage),
      });

      if (!response.ok) {
        throw new Error('Failed to update page');
      }

      resetForm();
      setIsEditing(false);
      setEditingId(null);
      setReload(!reload);
    } catch (error) {
      console.error('Error updating page:', error);
    }
  };

  const handleDeletePage = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/pages/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete page');
      }

      setReload(!reload);
    } catch (error) {
      console.error('Error deleting page:', error);
    }
  };

  const startEditing = (id, page) => {
    setIsEditing(true);
    setEditingId(id);
    setCurrentPage({
      title: page.title,
      content: page.content,
      footerLinkId: page.footerLink ? page.footerLink: ''
    });
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background', 'align', 'link', 'image'
  ];

  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Manage Pages</Heading>
      <Flex direction="column" gap={4}>
        <Input
          placeholder="Title"
          name="title"
          value={currentPage.title}
          onChange={handleInputChange}
        />
        <ReactQuill
          theme="snow"
          value={currentPage.content}
          onChange={handleContentChange}
          modules={modules}
          formats={formats}
        />
        <Select
          placeholder="Select Footer Link"
          name="footerLinkId"
          value={currentPage.footerLinkId}
          onChange={handleInputChange}
        >
          {footerLinks.map((link) => (
            <option key={link._id} value={link._id}>{link.title}</option>
          ))}
        </Select>
        {isEditing ? (
          <Button onClick={handleUpdatePage} colorScheme="blue">Update Page</Button>
        ) : (
          <Button onClick={handleAddPage} colorScheme="blue">Add Page</Button>
        )}
      </Flex>
      <Box mt={4}>
        {pages.map((page) => (
          <Flex key={page._id} justifyContent="space-between" alignItems="center" mb={2}>
            <Box>
              <Text fontWeight="bold">{page.title}</Text>
              <div
                dangerouslySetInnerHTML={{ __html: page.content }}
                style={{ maxHeight: '100px', overflow: 'hidden' }}
              />
            </Box>
            <Flex>
              <Button onClick={() => startEditing(page._id, page)} colorScheme="yellow" mr={2}>Edit</Button>
              <Button onClick={() => handleDeletePage(page._id)} colorScheme="red">Delete</Button>
            </Flex>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default ManagePages;
