import React, { useState } from 'react';
import { Box, Image, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure } from '@chakra-ui/react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const ZoomableImage = ({ src, alt }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setPosition({ x, y });
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
    setPosition({ x: 0, y: 0 });
  };

  const handleClick = () => {
    onOpen();
  };

  return (
    <>
      <Box
        overflow="hidden"
        position="relative"
        w="100%"
        h={{ base: '100%', sm: '400px', lg: '500px' }}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        cursor="pointer"
      >
        <Image
          src={src}
          alt={alt}
          w="100%"
          h="100%"
          objectFit="contain" // Ensure the image maintains its aspect ratio
          transform={isZoomed ? `scale(2)` : 'scale(1)'}
          transformOrigin={`${position.x}% ${position.y}%`}
          transition="transform 0.3s ease"
        />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Zoom>
              <Image src={src} alt={alt} w="100%" objectFit="contain" />
            </Zoom>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ZoomableImage;
