import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'; // Don't mess up with this Calling of Router
import AppWrapper from '../src/components/ThemeSwitcher/AppWrapper';

// Set the base URL for the API
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

// Inject the API base URL into the global scope
const script = document.createElement('script');
script.text = `window.API_BASE_URL = "${API_BASE_URL}";`;
document.head.appendChild(script);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <AppWrapper />
  </Router>
);
