import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Stack,
  Text,
  Flex,
  Input,
  IconButton,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useUser } from '../User/UserContext';
import { DeleteIcon, EditIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const CartPage = () => {
  const { cart, setCart } = useUser();
  const navigate = useNavigate();
  const toast = useToast();
  const [editIndex, setEditIndex] = useState(null);
  const [editQuantity, setEditQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchStock = async () => {
      if (editIndex !== null) {
        const product = cart[editIndex];
        try {
          const response = await fetch(`${API_BASE_URL}api/stock?productId=${product.productId}`);
          const data = await response.json();
          const selectedStock = data.find(
            stockItem => stockItem.colorId?._id === product.color?._id && stockItem.sizeId?._id === product.size?._id
          );
          if (selectedStock) {
            setMaxQuantity(selectedStock.quantity);
          }
        } catch (error) {
          console.error('Failed to fetch stock:', error);
        }
      }
    };
    fetchStock();
  }, [editIndex, cart]);

  const handleProceedToCheckout = () => {
    navigate('/checkout');
  };

  const handleDelete = (index) => {
    const newCart = cart.filter((_, i) => i !== index);
    setCart(newCart);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditQuantity(cart[index].quantity);
  };

  const handleSave = () => {
    if (editQuantity > maxQuantity) {
      toast({
        title: 'Erro',
        description: `Quantidade máxima disponível: ${maxQuantity}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newCart = cart.map((item, index) => {
      if (index === editIndex) {
        return { ...item, quantity: editQuantity };
      }
      return item;
    });
    setCart(newCart);
    setEditIndex(null);
  };

  const handleCancel = () => {
    setEditIndex(null);
  };

  return (
    <Box p={4} maxWidth={{ base: '720px', md: 'none' }} mx="auto">
      <Heading mb={4}>Carrinho</Heading>
      {cart.length === 0 ? (
        <Text>Seu carrinho está vazio.</Text>
      ) : (
        <>
          {!isMobile ? (
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Produto</Th>
                  <Th>Cor</Th>
                  <Th>Tamanho</Th>
                  <Th>Quantidade</Th>
                  <Th>Preço Unidade</Th>
                  <Th>Preço Total</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {cart.map((item, index) => (
                  <Tr key={index}>
                    <Td whiteSpace="pre-wrap" wordBreak="break-word">{item.name}</Td>
                    <Td>
                      <Box bg={item.color?.hex || 'transparent'} w="20px" h="20px" borderRadius="full" border="1px solid gray" />
                    </Td>
                    <Td whiteSpace="pre-wrap" wordBreak="break-word">{item.size?.name || 'N/A'}</Td>
                    <Td>
                      {editIndex === index ? (
                        <Flex alignItems="center">
                          <IconButton
                            icon={<MinusIcon />}
                            size="sm"
                            onClick={() => setEditQuantity(Math.max(1, editQuantity - 1))}
                            isDisabled={editQuantity <= 1}
                          />
                          <Input
                            value={editQuantity}
                            onChange={(e) => setEditQuantity(Number(e.target.value))}
                            width="60px"
                            textAlign="center"
                            mx={2}
                            type="number"
                            min="1"
                          />
                          <IconButton
                            icon={<AddIcon />}
                            size="sm"
                            onClick={() => setEditQuantity(Math.min(editQuantity + 1, maxQuantity))}
                            isDisabled={editQuantity >= maxQuantity}
                          />
                        </Flex>
                      ) : (
                        item.quantity
                      )}
                    </Td>
                    <Td whiteSpace="pre-wrap" wordBreak="break-word">
                      {item.budgetOn ? 'Sob Consulta' : `R$${item.price?.toFixed(2) || '0.00'}`}
                    </Td>
                    <Td whiteSpace="pre-wrap" wordBreak="break-word">
                      {item.budgetOn ? 'Sob Consulta' : `R$${(item.price * item.quantity)?.toFixed(2) || '0.00'}`}
                    </Td>
                    <Td>
                      {editIndex === index ? (
                        <Stack direction="row" spacing={2}>
                          <IconButton icon={<CheckIcon />} colorScheme="green" onClick={handleSave} />
                          <IconButton icon={<CloseIcon />} colorScheme="red" onClick={handleCancel} />
                        </Stack>
                      ) : (
                        <Stack direction="row" spacing={2}>
                          <IconButton icon={<EditIcon />} onClick={() => handleEdit(index)} />
                          <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={() => handleDelete(index)} />
                        </Stack>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Stack spacing={4}>
              {cart.map((item, index) => (
                <Box key={index} p={4} borderWidth="1px" borderRadius="lg">
                  <Flex justifyContent="space-between">
                    <Text fontWeight="bold" fontSize="sm" whiteSpace="pre-wrap" wordBreak="break-word">{item.name}</Text>
                    <Stack direction="row" spacing={2}>
                      {editIndex === index ? (
                        <>
                          <IconButton icon={<CheckIcon />} colorScheme="green" size="sm" onClick={handleSave} />
                          <IconButton icon={<CloseIcon />} colorScheme="red" size="sm" onClick={handleCancel} />
                        </>
                      ) : (
                        <>
                          <IconButton icon={<EditIcon />} size="sm" onClick={() => handleEdit(index)} />
                          <IconButton icon={<DeleteIcon />} colorScheme="red" size="sm" onClick={() => handleDelete(index)} />
                        </>
                      )}
                    </Stack>
                  </Flex>
                  <Flex mt={2}>
                    <Box bg={item.color?.hex || 'transparent'} w="20px" h="20px" borderRadius="full" border="1px solid gray" mr={2} />
                    <Text fontSize="sm" whiteSpace="pre-wrap" wordBreak="break-word">{item.size?.name || 'Sob Consulta'}</Text>
                  </Flex>
                  <Flex mt={2} justifyContent="space-between">
                    <Text fontSize="sm" whiteSpace="pre-wrap" wordBreak="break-word">Quantidade:</Text>
                    {editIndex === index ? (
                      <Flex alignItems="center">
                        <IconButton
                          icon={<MinusIcon />}
                          size="sm"
                          onClick={() => setEditQuantity(Math.max(1, editQuantity - 1))}
                          isDisabled={editQuantity <= 1}
                        />
                        <Input
                          value={editQuantity}
                          onChange={(e) => setEditQuantity(Number(e.target.value))}
                          width="60px"
                          textAlign="center"
                          mx={2}
                          type="number"
                          min="1"
                          fontSize="sm"
                        />
                        <IconButton
                          icon={<AddIcon />}
                          size="sm"
                          onClick={() => setEditQuantity(Math.min(editQuantity + 1, maxQuantity))}
                          isDisabled={editQuantity >= maxQuantity}
                        />
                      </Flex>
                    ) : (
                      <Text fontSize="sm" whiteSpace="pre-wrap" wordBreak="break-word">{item.quantity}</Text>
                    )}
                  </Flex>
                  <Flex mt={2} justifyContent="space-between">
                    <Text fontSize="sm" whiteSpace="pre-wrap" wordBreak="break-word">Preço Unidade:</Text>
                    <Text fontSize="sm" whiteSpace="pre-wrap" wordBreak="break-word">
                      {item.budgetOn ? 'Sob Consulta' : `R$${item.price?.toFixed(2) || '0.00'}`}
                    </Text>
                  </Flex>
                  <Flex mt={2} justifyContent="space-between">
                    <Text fontSize="sm" whiteSpace="pre-wrap" wordBreak="break-word">Preço Total:</Text>
                    <Text fontSize="sm" whiteSpace="pre-wrap" wordBreak="break-word">
                      {item.budgetOn ? 'Sob Consulta' : `R$${(item.price * item.quantity)?.toFixed(2) || '0.00'}`}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </Stack>
          )}
        </>
      )}
      <Flex mt={4} justifyContent="space-between">
        <Button as={RouterLink} to="/" colorScheme="blue">
          Comprar Mais
        </Button>
        <Button onClick={handleProceedToCheckout} colorScheme="green">
          Finalizar
        </Button>
      </Flex>
    </Box>
  );
};

export default CartPage;
