import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  SimpleGrid,
  Heading,
  Select,
  useTheme,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ManageItems = () => {
  const theme = useTheme();
  const toast = useToast();
  const brandColor = (theme.colors.brand && theme.colors.brand[500]) || 'teal.500';

  const [genders, setGenders] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newGender, setNewGender] = useState('');
  const [newItemType, setNewItemType] = useState({ name: '', gender: '' });
  const [newCategory, setNewCategory] = useState({ name: '', itemType: '' });
  const [editing, setEditing] = useState({ gender: null, itemType: null, category: null });

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteType, setDeleteType] = useState('');
  const [confirmName, setConfirmName] = useState('');
  const cancelRef = useRef();

  useEffect(() => {
    fetchData('api/genders', setGenders);
    fetchData('api/itemtypes', setItemTypes);
    fetchData('api/categories', setCategories);
  }, []);

  const fetchData = async (endpoint, setState) => {
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`);
      const data = await response.json();
      setState(data);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
    }
  };

  const validateInput = (type, value) => {
    switch (type) {
      case 'gender':
        if (value.length > 20) {
          toast({
            title: "Entrada Inválida",
            description: "O gênero não pode exceder 20 caracteres.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return false;
        }
        break;
      case 'itemType':
        if (value.length > 28) {
          toast({
            title: "Entrada Inválida",
            description: "O tipo de item não pode exceder 28 caracteres.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return false;
        }
        break;
      case 'category':
        if (value.length > 20) {
          toast({
            title: "Entrada Inválida",
            description: "A categoria não pode exceder 20 caracteres.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };

  const handleAddOrEdit = async (type, newItem, setState, endpoint) => {
    if (!validateInput(type, newItem.name)) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const method = editing[type] ? 'PUT' : 'POST';
      const url = editing[type] ? `${API_BASE_URL}${endpoint}/${editing[type]}` : `${API_BASE_URL}${endpoint}`;
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newItem),
      });

      if (response.ok) {
        fetchData(endpoint, setState);
        resetForm(type);

        const savedItem = await response.json();
        if (type === 'gender') {
          if (method === 'POST') {
            await createNavItem({ label: savedItem.name, href: `/navitem/${savedItem._id}` });
          } else if (method === 'PUT') {
            await updateNavItem(editing[type], { label: savedItem.name, href: `/navitem/${savedItem._id}` });
          }
        } else if (type === 'itemType') {
          const gender = genders.find(g => g._id === newItem.gender);
          if (method === 'POST') {
            await addItemTypeToNav(gender.name, { label: savedItem.name, href: `/itemtype/${savedItem._id}` });
          } else if (method === 'PUT') {
            await updateItemTypeInNav( editing[type], { label: savedItem.name, href: `/itemtype/${savedItem._id}` });
          }
        }

      } else {
        alert(`Failed to add or update ${type}`);
      }
    } catch (error) {
      console.error(`Error adding or updating ${type}:`, error);
    }
  };

  const createNavItem = async (navItem) => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`${API_BASE_URL}api/navitems`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(navItem),
      });
    } catch (error) {
      console.error('Error creating NavItem:', error);
    }
  };

  const updateNavItem = async (id, navItem) => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`${API_BASE_URL}api/navitems/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(navItem),
      });
    } catch (error) {
      console.error('Error updating NavItem:', error);
    }
  };

  const addItemTypeToNav = async (genderName, itemTypeNavItem) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}api/navitems`);
      const navItems = await response.json();
      
      const genderNavItem = navItems.find(item => item.label === genderName);
      if (genderNavItem) {
        genderNavItem.children.push(itemTypeNavItem);
        await fetch(`${API_BASE_URL}api/navitems/${genderNavItem._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(genderNavItem),
        });
      }
    } catch (error) {
      console.error('Error adding item type to Nav:', error);
    }
  };

  const updateItemTypeInNav = async (itemTypeId, itemTypeNavItem) => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`${API_BASE_URL}api/navitems/itemtypenav/${itemTypeId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(itemTypeNavItem),
      });
    } catch (error) {
      console.error('Error updating item type in Nav:', error);
    }
  };

  const handleDelete = async (endpoint, id, setState) => {
    setIsDeleteDialogOpen(false);
    if (deleteItem.name !== confirmName) {
      toast({
        title: "Nome incorreto",
        description: "O nome inserido não corresponde ao nome do item.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}${endpoint}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        alert('Failed to delete item');
      } else {
        fetchData(endpoint, setState);

        if (deleteType === 'gender') {
          await deleteNavItem(id);
        } else if (deleteType === 'itemType') {          
          await deleteItemTypeFromNav( id);
        }
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const deleteNavItem = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}api/navitems`);
      const navItems = await response.json();
      
      const navItemToDelete = navItems.find(item => item.label === deleteItem.name);
      if (navItemToDelete) {
        await fetch(`${API_BASE_URL}api/navitems/${navItemToDelete._id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to delete nav item');
        }
      }
    } catch (error) {
      console.error('Error deleting NavItem:', error);
    }
  }; 

  const deleteItemTypeFromNav = async (itemTypeId) => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`${API_BASE_URL}api/navitems/itemtypenav/${itemTypeId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error deleting item type from Nav:', error);
    }
  };

  const confirmDelete = (type, item) => {
    setDeleteType(type);
    setDeleteItem(item);
    setIsDeleteDialogOpen(true);
  };

  const resetDeleteDialog = () => {
    setConfirmName('');
    setIsDeleteDialogOpen(false);
  };

  const resetForm = (type) => {
    switch (type) {
      case 'gender':
        setNewGender('');
        setEditing({ ...editing, gender: null });
        break;
      case 'itemType':
        setNewItemType({ name: '', gender: '' });
        setEditing({ ...editing, itemType: null });
        break;
      case 'category':
        setNewCategory({ name: '', itemType: '' });
        setEditing({ ...editing, category: null });
        break;
      default:
        break;
    }
  };

  const handleEdit = (type, item) => {
    switch (type) {
      case 'gender':
        setNewGender(item.name);
        setEditing({ ...editing, gender: item._id });
        break;
      case 'itemType':
        setNewItemType({ name: item.name, gender: item.gender._id });
        setEditing({ ...editing, itemType: item._id });
        break;
      case 'category':
        setNewCategory({ name: item.name, itemType: item.itemType._id });
        setEditing({ ...editing, category: item._id });
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <Heading mb={6} color={brandColor}>Controle de Categorias dos itens</Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Box>
          <Heading size="md" color={brandColor}>{editing.gender ? 'Editar Gênero' : 'Adicionar Gênero'}</Heading>
          <VStack align="start">
            <FormControl>
              <FormLabel>Gênero</FormLabel>
              <Input value={newGender} onChange={(e) => setNewGender(e.target.value)} />
            </FormControl>
            <Button colorScheme="blue" onClick={() => handleAddOrEdit('gender', { name: newGender }, setGenders, 'api/genders')}>{editing.gender ? 'Atualizar' : 'Adicionar'}</Button>
          </VStack>
          <Box mt={4}>
            <Heading size="sm" color={brandColor}>Lista de Gêneros</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {genders.map(gender => (
                  <Tr key={gender._id}>
                    <Td>{gender.name}</Td>
                    <Td>
                      <IconButton
                        icon={<EditIcon />}
                        mr={2}
                        onClick={() => handleEdit('gender', gender)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => confirmDelete('gender', gender)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        <Box>
          <Heading size="md" color={brandColor}>{editing.itemType ? 'Editar Tipo de Item' : 'Adicionar Tipo de Item'}</Heading>
          <VStack align="start">
            <FormControl>
              <FormLabel>Tipo de Item</FormLabel>
              <Input value={newItemType.name} onChange={(e) => setNewItemType({ ...newItemType, name: e.target.value })} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Gênero</FormLabel>
              <Select value={newItemType.gender} onChange={(e) => setNewItemType({ ...newItemType, gender: e.target.value })}>
                <option value="">Selecionar Gênero</option>
                {genders.map(gender => (
                  <option key={gender._id} value={gender._id}>{gender.name}</option>
                ))}
              </Select>
            </FormControl>
            <Button colorScheme="blue" onClick={() => handleAddOrEdit('itemType', newItemType, setItemTypes, 'api/itemtypes')}>{editing.itemType ? 'Atualizar' : 'Adicionar'}</Button>
          </VStack>
          <Box mt={4}>
            <Heading size="sm" color={brandColor}>Lista de Tipos de Item</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Gênero</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {itemTypes.map(itemType => (
                  <Tr key={itemType._id}>
                    <Td>{itemType.name}</Td>
                    <Td>{genders.find(gender => gender._id === itemType.gender._id)?.name || 'N/A'}</Td>
                    <Td>
                      <IconButton
                        icon={<EditIcon />}
                        mr={2}
                        onClick={() => handleEdit('itemType', itemType)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => confirmDelete('itemType', itemType)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </SimpleGrid>

      <Box mt={10}>
        <Heading size="md" color={brandColor}>{editing.category ? 'Editar Categoria' : 'Adicionar Categoria'}</Heading>
        <VStack align="start">
          <FormControl>
            <FormLabel>Categoria</FormLabel>
            <Input value={newCategory.name} onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Tipo de Item</FormLabel>
            <Select value={newCategory.itemType} onChange={(e) => setNewCategory({ ...newCategory, itemType: e.target.value })}>
              <option value="">Selecionar Tipo de Item</option>
              {genders.map(gender => (
                <optgroup key={gender._id} label={gender.name}>
                  {itemTypes
                    .filter(itemType => itemType.gender._id === gender._id)
                    .map(itemType => (
                      <option key={itemType._id} value={itemType._id}>
                        {itemType.name}
                      </option>
                    ))}
                </optgroup>
              ))}
            </Select>
          </FormControl>
          <Button colorScheme="blue" onClick={() => handleAddOrEdit('category', newCategory, setCategories, 'api/categories')}>{editing.category ? 'Atualizar' : 'Adicionar'}</Button>
        </VStack>
        <Box mt={4}>
          <Heading size="sm" color={brandColor}>Lista de Categorias</Heading>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>Tipo de Item</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {categories.map(category => (
                <Tr key={category._id}>
                  <Td>{category.name}</Td>
                  <Td>{itemTypes.find(it => it._id === category.itemType._id)?.name || 'N/A'}</Td>
                  <Td>
                    <IconButton
                      icon={<EditIcon />}
                      mr={2}
                      onClick={() => handleEdit('category', category)}
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={() => confirmDelete('category', category)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={resetDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar Exclusão
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir <strong>{deleteItem?.name}?</strong> Por favor, insira o nome do item para confirmar.
              <Input
                placeholder="Nome do item"
                value={confirmName}
                onChange={(e) => setConfirmName(e.target.value)}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={resetDeleteDialog}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={() => handleDelete(`api/${deleteType}s`, deleteItem._id, deleteType === 'gender' ? setGenders : deleteType === 'itemType' ? setItemTypes : setCategories)} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ManageItems;
