import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from 'react-router-dom';

const SiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const Register = () => {
  const theme = useTheme();
  const brandColor = theme.colors?.brand?.[500] || 'blue.500';
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();

  const handleShowPasswordClick = () => setShowPassword(!showPassword);
  const handleShowConfirmPasswordClick = () => setShowConfirmPassword(!showConfirmPassword);
  const handleCaptchaChange = (value) => setCaptchaValue(value);
  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const recaptchaResponse = await fetch(`${API_BASE_URL}api/recaptcha/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: captchaValue }),
      });

      const recaptchaData = await recaptchaResponse.json();

      if (recaptchaData.success) {
        const response = await fetch(`${API_BASE_URL}api/users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.fullName,
            email: formData.email,
            phone: formData.phone,
            password: formData.password,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          localStorage.setItem('token', data.token); // Store JWT token
          navigate('/account'); // Redirect to account page
        } else {
          alert(data.message || 'Error registering user');
        }
      } else {
        alert(`Captcha verification failed: ${recaptchaData.message}`);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      alert(`Error during registration: ${error.message}`);
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg="lightgray"
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Criar uma conta</Heading>
          <Text fontSize={'lg'} color={brandColor}>
            Compre mais rápido e acompanhe seus pedidos em um só lugar!
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={brandColor}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4} as="form" onSubmit={handleSubmit}>
            <InputGroup>
              <Input
                type="text"
                name="fullName"
                placeholder="Nome completo"
                value={formData.fullName}
                onChange={handleInputChange}
                bg="white"
              />
            </InputGroup>
            <InputGroup>
              <Input
                type="email"
                name="email"
                placeholder="E-mail"
                value={formData.email}
                onChange={handleInputChange}
                bg="white"
              />
            </InputGroup>
            <InputGroup>
              <Input
                type="tel"
                name="phone"
                placeholder="Telefone (opcional)"
                value={formData.phone}
                onChange={handleInputChange}
                bg="white"
              />
            </InputGroup>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Senha"
                value={formData.password}
                onChange={handleInputChange}
                bg="white"
              />
              <InputRightElement h={'full'}>
                <Button
                  variant={'ghost'}
                  onClick={handleShowPasswordClick}
                  bg="white"
                >
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <InputGroup>
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                placeholder="Confirmar senha"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                bg="white"
              />
              <InputRightElement h={'full'}>
                <Button
                  variant={'ghost'}
                  onClick={handleShowConfirmPasswordClick}
                  bg="white"
                >
                  {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            {SiteKey && (
              <ReCAPTCHA
                sitekey={SiteKey}
                onChange={handleCaptchaChange}
              />
            )}
            <Button
              type="submit"
              bg={'green.200'}
              color={'white'}
              _hover={{
                bg: 'green.700',
              }}
              isDisabled={!captchaValue} // Disable button if captcha is not solved
            >
              CRIAR UMA CONTA
            </Button>
            <Stack pt={6}>
              <Text align={'center'}>
                Já possui uma conta? <Link to="/login" color={'blue.400'}>Iniciar sessão</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Register;
