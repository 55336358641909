// sellerMockData.js
const sellerMockData = [
    { id: '01e4dsa', seller: 'Vendedor A', clientsQuantity: 10, salesVolume: 'R$1000', tickets: 15 },
    { id: '0315dsaa', seller: 'Vendedor B', clientsQuantity: 20, salesVolume: 'R$2000', tickets: 25 },
    { id: '01e4dsa', seller: 'Vendedor C', clientsQuantity: 15, salesVolume: 'R$1500', tickets: 18 },
    { id: '51034szv', seller: 'Vendedor D', clientsQuantity: 8, salesVolume: 'R$800', tickets: 10 },
    { id: '789sadas', seller: 'Vendedor E', clientsQuantity: 12, salesVolume: 'R$1200', tickets: 13 },
    { id: '98fdsa32', seller: 'Vendedor F', clientsQuantity: 25, salesVolume: 'R$2500', tickets: 30 },
    { id: '21dfas43', seller: 'Vendedor G', clientsQuantity: 18, salesVolume: 'R$1800', tickets: 22 },
    { id: 'af12dsaf', seller: 'Vendedor H', clientsQuantity: 7, salesVolume: 'R$700', tickets: 9 },
    { id: 'fa34232f', seller: 'Vendedor I', clientsQuantity: 22, salesVolume: 'R$2200', tickets: 28 },
    { id: 'vbn43434', seller: 'Vendedor J', clientsQuantity: 16, salesVolume: 'R$1600', tickets: 20 },
    { id: 'rewr3245', seller: 'Vendedor K', clientsQuantity: 14, salesVolume: 'R$1400', tickets: 17 },
    { id: 'erwre324', seller: 'Vendedor L', clientsQuantity: 19, salesVolume: 'R$1900', tickets: 24 },
    // Add more entries as needed
  ];
  
  export default sellerMockData;