import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Input, Table, Tbody, Td, Th, Thead, Tr, Heading } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const TextTypeManager = () => {
  const [textTypes, setTextTypes] = useState([]);
  const [currentText, setCurrentText] = useState({ title: '', content: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchTextTypes();
  }, []);

  const fetchTextTypes = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/texttypes`);
      const data = await response.json();
      setTextTypes(data);
    } catch (error) {
      console.error('Failed to fetch text types:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentText({ ...currentText, [name]: value });
  };

  const handleContentChange = (content) => {
    setCurrentText({ ...currentText, content });
  };

  const handleAddTextType = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/texttypes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentText),
      });

      if (!response.ok) {
        throw new Error('Failed to add text type');
      }

      fetchTextTypes();
      setCurrentText({ title: '', content: '' });
    } catch (error) {
      console.error('Error adding text type:', error);
    }
  };

  const handleUpdateTextType = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/texttypes/${editingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentText),
      });

      if (!response.ok) {
        throw new Error('Failed to update text type');
      }

      fetchTextTypes();
      setCurrentText({ title: '', content: '' });
      setIsEditing(false);
      setEditingId(null);
    } catch (error) {
      console.error('Error updating text type:', error);
    }
  };

  const handleDeleteTextType = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/texttypes/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete text type');
      }

      fetchTextTypes();
    } catch (error) {
      console.error('Error deleting text type:', error);
    }
  };

  const startEditing = (id, text) => {
    setIsEditing(true);
    setEditingId(id);
    setCurrentText(text);
  };

  const modules = {
    toolbar: [
      [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }], // add color and background options
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background', 'align', 'link', 'image'
  ];

  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Manage Text Types</Heading>
      <Flex mb={4} direction="column" gap={4}>
        <Input
          placeholder="Title"
          name="title"
          value={currentText.title}
          onChange={handleInputChange}
        />
        <ReactQuill
          theme="snow"
          value={currentText.content}
          onChange={handleContentChange}
          modules={modules}
          formats={formats}
        />
        {isEditing ? (
          <Button onClick={handleUpdateTextType} colorScheme="blue">Update Text Type</Button>
        ) : (
          <Button onClick={handleAddTextType} colorScheme="blue">Add Text Type</Button>
        )}
      </Flex>
      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Content</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {textTypes.map((textType) => (
            <Tr key={textType._id}>
              <Td>{textType.title}</Td>
              <Td>
                <div
                  dangerouslySetInnerHTML={{ __html: textType.content }}
                  style={{ maxHeight: '100px', overflow: 'hidden' }}
                />
              </Td>
              <Td>
                <Button onClick={() => startEditing(textType._id, textType)} colorScheme="yellow" mr={2}>
                  Edit
                </Button>
                <Button onClick={() => handleDeleteTextType(textType._id)} colorScheme="red">
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TextTypeManager;
