import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Checkbox,
  useTheme,
  Heading,
  Flex,
  Spacer
} from '@chakra-ui/react';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ContactManager = () => {
  const theme = useTheme();
  const brandColor = theme.colors.brand[500];
  const [formData, setFormData] = useState({
    whatsapp: '',
    phone: '',
    email: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    pais: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    facebookEnabled: false,
    instagramEnabled: false,
    twitterEnabled: false,
    youtubeEnabled: false,
  });

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/contacts`);
        const data = await response.json();
        setFormData(data);
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    };

    fetchContacts();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}api/contacts`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Contacts saved successfully');
      } else {
        alert('Failed to save contacts');
      }
    } catch (error) {
      console.error('Error saving contact details:', error);
    }
  };

  const checkCEP = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      if (!response.ok) {
        throw new Error('Failed to fetch CEP data');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching CEP data:', error);
      return null;
    }
  };

  const handleCEPBlur = async (e) => {
    const cep = e.target.value.replace(/\D/g, '');
    if (cep.length === 8) {
      const data = await checkCEP(cep);
      if (data && !data.erro) {
        setFormData({
          ...formData,
          cep,
          endereco: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          estado: data.uf,
        });
      }
    }
  };

  return (
    <Box>
      <Heading mb={6} color={brandColor}>Editar Contatos</Heading>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel><strong>WhatsApp</strong></FormLabel>
          <Input name="whatsapp" value={formData.whatsapp} onChange={handleChange} />
        </FormControl>
        <FormControl>
          <FormLabel><strong>Telefone</strong></FormLabel>
          <Input name="phone" value={formData.phone} onChange={handleChange} />
        </FormControl>
        <FormControl>
          <FormLabel><strong>Email</strong></FormLabel>
          <Input name="email" value={formData.email} onChange={handleChange} />
        </FormControl>
        <Flex mt={2}>
          <FormControl isRequired>
            <FormLabel><strong>CEP</strong></FormLabel>
            <Input
              name="cep"
              value={formData.cep}
              onChange={handleChange}
              onBlur={handleCEPBlur}
              placeholder="xxxxx-xxx"
            />
          </FormControl>
          <Spacer />
          <FormControl isRequired>
            <FormLabel><strong>Endereço</strong></FormLabel>
            <Input
              name="endereco"
              value={formData.endereco}
              onChange={handleChange}
              placeholder="Endereço"
            />
          </FormControl>
          <Spacer />
          <FormControl isRequired>
            <FormLabel><strong>Número</strong></FormLabel>
            <Input
              name="numero"
              value={formData.numero}
              onChange={handleChange}
              placeholder="Número"
            />
          </FormControl>
        </Flex>
        <Flex gap="20px">
          <FormControl>
            <FormLabel><strong>Complemento</strong></FormLabel>
            <Input
              name="complemento"
              value={formData.complemento}
              onChange={handleChange}
              placeholder="Complemento"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel><strong>Bairro</strong></FormLabel>
            <Input
              name="bairro"
              value={formData.bairro}
              onChange={handleChange}
              placeholder="Bairro"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel><strong>Cidade</strong></FormLabel>
            <Input
              name="cidade"
              value={formData.cidade}
              onChange={handleChange}
              placeholder="Cidade"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel><strong>Estado</strong></FormLabel>
            <Input
              name="estado"
              value={formData.estado}
              onChange={handleChange}
              placeholder="Estado"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel><strong>País</strong></FormLabel>
            <Input
              name="pais"
              value={formData.pais}
              onChange={handleChange}
              placeholder="País"
            />
          </FormControl>
        </Flex>
        <FormControl>
          <FormLabel><strong>Facebook</strong></FormLabel>
          <Input name="facebook" value={formData.facebook} onChange={handleChange} />
          <Checkbox name="facebookEnabled" isChecked={formData.facebookEnabled} onChange={handleChange}>
            Habilitar Facebook
          </Checkbox>
        </FormControl>
        <FormControl>
          <FormLabel><strong>Instagram</strong></FormLabel>
          <Input name="instagram" value={formData.instagram} onChange={handleChange} />
          <Checkbox name="instagramEnabled" isChecked={formData.instagramEnabled} onChange={handleChange}>
            Habilitar Instagram
          </Checkbox>
        </FormControl>
        <FormControl>
          <FormLabel><strong>Twitter</strong></FormLabel>
          <Input name="twitter" value={formData.twitter} onChange={handleChange} />
          <Checkbox name="twitterEnabled" isChecked={formData.twitterEnabled} onChange={handleChange}>
            Habilitar Twitter
          </Checkbox>
        </FormControl>
        <FormControl>
          <FormLabel><strong>YouTube</strong></FormLabel>
          <Input name="youtube" value={formData.youtube} onChange={handleChange} />
          <Checkbox name="youtubeEnabled" isChecked={formData.youtubeEnabled} onChange={handleChange}>
            Habilitar YouTube
          </Checkbox>
        </FormControl>
        <Button colorScheme="blue" onClick={handleSave}>Salvar Contatos</Button>
      </Stack>
    </Box>
  );
};

export default ContactManager;
