import React, { useState, useMemo } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import transactionMockData from './MockData';

const Transactions = () => {
  const [sortConfig, setSortConfig] = useState({ key: 'product', direction: 'ascending' });

  const sortedTransactions = useMemo(() => {
    let sortableItems = [...transactionMockData];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <ChevronUpIcon /> : <ChevronDownIcon />;
    }
    return null;
  };

  return (
    <Box>
      <Text color="white" mb={1}>Tabela Vendas</Text>
      <Box
        maxHeight="auto"
        overflowY="auto"
        p={3}
        borderRadius="md"
        bg="gray.700"
      >
        <Flex justify="space-between" bg="gray.800" p={3} borderRadius="md" mb={2}>
          <Text color="white" fontWeight="bold" cursor="pointer" onClick={() => handleSort('product')}>
            Produto {getSortIcon('product')}
          </Text>
          <Text color="white" fontWeight="bold" cursor="pointer" onClick={() => handleSort('quantity')}>
            Qtd. Vendas {getSortIcon('quantity')}
          </Text>
          <Text color="white" fontWeight="bold" cursor="pointer" onClick={() => handleSort('volume')}>
            Volume Vendas {getSortIcon('volume')}
          </Text>
        </Flex>
        {sortedTransactions.map((tx, index) => (
          <Flex key={index} justify="space-between" bg="gray.600" p={3} borderRadius="md" mb={2}>
            <Text color="white">{tx.product}</Text>
            <Text color="white">{tx.quantity}</Text>
            <Text color="green.400">{tx.volume}</Text>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default Transactions;
