import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Text,
  HStack,
  Image,
  VStack,
} from '@chakra-ui/react';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const ShippingModal = ({ isOpen, onClose, orderTotal, orderId }) => {
  const [cepEmpresa, setCepEmpresa] = useState('');
  const [cep, setCep] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [length, setLength] = useState('');
  const [weight, setWeight] = useState('');
  const [insuranceValue, setInsuranceValue] = useState('');
  const [quantity, setQuantity] = useState('');
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchCEPEmpresa = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/contacts`);
        const data = await response.json();
        setCepEmpresa(data.cep);
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    };

    fetchCEPEmpresa();
  }, []);

  const handleCalculateShipping = async () => {
    if (!cep) {
      toast({
        title: 'Erro',
        description: 'Por favor, insira um CEP válido.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const shipmentDetails = {
      from: { postal_code: cepEmpresa },
      to: { postal_code: cep },
      products: [
        {
          id: '1',
          width: parseFloat(width),
          height: parseFloat(height),
          length: parseFloat(length),
          weight: parseFloat(weight),
          insurance_value: parseFloat(insuranceValue),
          quantity: parseInt(quantity, 10),
        },
      ],
    };

    try {
      const response = await fetch(`${API_BASE_URL}api/melhorEnvio/quotes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(shipmentDetails),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch shipping quotes');
      }

      const data = await response.json();
      setShippingOptions(data); // Assume data contains the array of shipping options
    } catch (error) {
      console.error('Error calculating shipping:', error);
      toast({
        title: 'Erro',
        description: 'Falha ao calcular o frete.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSaveShipping = async () => {
    if (!selectedOption) {
      toast({
        title: 'Erro',
        description: 'Por favor, selecione uma opção de frete.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}api/orders/${orderId}/add-shipping`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ shippingCost: selectedOption.price }),
      });

      if (!response.ok) {
        throw new Error('Failed to save shipping cost');
      }

      toast({
        title: 'Sucesso',
        description: 'Custo de frete adicionado ao pedido.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error saving shipping cost:', error);
      toast({
        title: 'Erro',
        description: 'Falha ao salvar o custo de frete.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Calcular Frete</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>CEP</FormLabel>
            <Input value={cep} onChange={(e) => setCep(e.target.value)} placeholder="CEP" />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Largura (cm)</FormLabel>
            <Input value={width} onChange={(e) => setWidth(e.target.value)} placeholder="Largura" />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Altura (cm)</FormLabel>
            <Input value={height} onChange={(e) => setHeight(e.target.value)} placeholder="Altura" />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Comprimento (cm)</FormLabel>
            <Input value={length} onChange={(e) => setLength(e.target.value)} placeholder="Comprimento" />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Peso (kg)</FormLabel>
            <Input value={weight} onChange={(e) => setWeight(e.target.value)} placeholder="Peso" />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Valor do Seguro</FormLabel>
            <Input value={insuranceValue} onChange={(e) => setInsuranceValue(e.target.value)} placeholder="Valor do Seguro" />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Quantidade</FormLabel>
            <Input value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="Quantidade" />
          </FormControl>
          <Button colorScheme="blue" onClick={handleCalculateShipping}>Calcular Frete</Button>
          {shippingOptions.length > 0 && (
            <Box mt={4} maxH="300px" overflowY="auto">
              <VStack spacing={5}>
                {shippingOptions.map((option, idx) => (
                  <Box
                    key={idx}
                    p={4}
                    borderWidth={1}
                    borderRadius="lg"
                    onClick={() => setSelectedOption(option)}
                    bg={selectedOption && selectedOption.id === option.id ? 'blue.100' : 'white'}
                    cursor="pointer"
                    w="100%"
                  >
                    <HStack spacing={4}>
                      <Image boxSize="50px" src={option.company.picture} alt={option.company.name} />
                      <Box>
                        <Text fontSize="md" fontWeight="bold">{option.company.name} - {option.name}</Text>
                        {option.error ? (
                          <Text fontSize="sm" color="red.500">{option.error}</Text>
                        ) : (
                          <>
                            <Text fontSize="sm">Preço: {option.currency}{option.price}</Text>
                            <Text fontSize="sm">Prazo de entrega: {option.delivery_range.min} - {option.delivery_range.max} dias úteis</Text>
                          </>
                        )}
                      </Box>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
          {selectedOption && (
            <Box mt={4}>
              <Text>Custo de Frete Selecionado: R${parseFloat(selectedOption.price).toFixed(2)}</Text>
              <Text>Total: R${(parseFloat(orderTotal) + parseFloat(selectedOption.price)).toFixed(2)}</Text>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSaveShipping} disabled={!selectedOption}>
            Salvar Frete
          </Button>
          <Button colorScheme="gray" onClick={onClose}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShippingModal;
