import React from 'react';
import { Box, Heading, Stack } from '@chakra-ui/react';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';

const SettingsPage = ({ setCustomTheme }) => {
  return (
    <Box p={4}>
      <Heading mb={4}>Configurações de Tema</Heading>
      <Stack spacing={4}>
        <ThemeSwitcher setCustomTheme={setCustomTheme} />
      </Stack>
    </Box>
  );
};

export default SettingsPage;
