import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const DynamicPage = () => {
  const { url } = useParams();
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    fetchPageContent();
  }, [url]);

  const fetchPageContent = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/footer/pages/url/${url}`);
      const data = await response.json();
      setPageContent(data);
    } catch (error) {
      console.error('Failed to fetch page content:', error);
    }
  };

  if (!pageContent) {
    return <div>Loading...</div>;
  }

  return (
    <Box p={4}>
      <Heading>{pageContent.title}</Heading>
      <Box mt={4} dangerouslySetInnerHTML={{ __html: pageContent.content }} />
    </Box>
  );
};

export default DynamicPage;
