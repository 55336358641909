import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Heading,
  Text,
  Icon,
  useTheme,
} from '@chakra-ui/react';
import { FaWhatsapp, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';

const SiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ContactForm = () => {
  const theme = useTheme();
  const brandColor = theme.colors.brand[500];
  const [contacts, setContacts] = useState({
    whatsapp: 'Loading...',
    phone: 'Loading...',
    email: 'Loading...',
    address: 'Loading...',
  });

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/contacts`);
        const data = await response.json();
        setContacts(data);
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    };

    fetchContacts();
  }, []);

  const handleRecaptchaChange = (value) => {
    console.log('Captcha value:', value);
  };

  return (
    <Container maxW="container.lg" py={12}>
      <Flex justify="space-between" flexDirection={{ base: 'column', md: 'row' }}>
        <Box flex={1} mr={{ md: 6 }}>
          <Heading mb={6} color={brandColor}>Contato</Heading>
          <Stack spacing={4}>
            <Flex align="center">
              <Icon as={FaWhatsapp} w={6} h={6} color={brandColor} />
              <Text ml={2} fontSize="lg" color={brandColor}>{contacts.whatsapp}</Text>
            </Flex>
            <Flex align="center">
              <Icon as={FaPhoneAlt} w={6} h={6} color={brandColor} />
              <Text ml={2} fontSize="lg" color={brandColor}>{contacts.phone}</Text>
            </Flex>
            <Flex align="center">
              <Icon as={FaEnvelope} w={6} h={6} color={brandColor} />
              <Text ml={2} fontSize="lg" color={brandColor}>{contacts.email}</Text>
            </Flex>
            <Flex align="center">
              <Icon as={FaMapMarkerAlt} w={6} h={6} color={brandColor} />
              <Text ml={2} fontSize="lg" color={brandColor}>{contacts.endereco} {contacts.numero} - {contacts.bairro}, {contacts.cidade} - {contacts.estado}, {contacts.cep}</Text>
            </Flex>
          </Stack>
        </Box>
        <Box flex={2}>
          <Stack spacing={4}>
            <FormControl id="name">
              <FormLabel>Nome completo</FormLabel>
              <Input type="text" borderColor={brandColor} />
            </FormControl>
            <FormControl id="email">
              <FormLabel>E-mail</FormLabel>
              <Input type="email" borderColor={brandColor} />
            </FormControl>
            <FormControl id="phone">
              <FormLabel>Telefone</FormLabel>
              <Input type="text" borderColor={brandColor} />
            </FormControl>
            <FormControl id="message">
              <FormLabel>Mensagem</FormLabel>
              <Textarea borderColor={brandColor} />
            </FormControl>
            {SiteKey && (
              <ReCAPTCHA
                sitekey={SiteKey}
                onChange={handleRecaptchaChange}
              />
            )}
            <Button colorScheme="olive" mt={4} width="full">
              ENVIAR
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Container>
  );
};

export default ContactForm;
