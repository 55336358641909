import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  SimpleGrid,
  Heading,
  useTheme,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { ChromePicker } from 'react-color';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ManageAttributes = () => {
  const theme = useTheme();
  const toast = useToast();
  const brandColor = (theme.colors.brand && theme.colors.brand[500]) || 'teal.500';

  const [sizes, setSizes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [newSize, setNewSize] = useState('');
  const [newBrand, setNewBrand] = useState('');
  const [newColor, setNewColor] = useState({ name: '', hex: '#000000' });
  const [editing, setEditing] = useState({ size: null, brand: null, color: null });

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteType, setDeleteType] = useState('');
  const [confirmName, setConfirmName] = useState('');
  const cancelRef = useRef();

  useEffect(() => {
    fetchData('api/sizes', setSizes);
    fetchData('api/brands', setBrands);
    fetchData('api/colors', setColors);
  }, []);

  const fetchData = async (endpoint, setState) => {
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`);
      const data = await response.json();
      setState(data);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
    }
  };

  const validateInput = (type, value) => {
    switch (type) {
      case 'size':
        if (value.length > 5) {
          toast({
            title: "Tamanho Inválido",
            description: "O tamanho não pode exceder 5 caracteres.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return false;
        }
        break;
      case 'brand':
        if (value.length > 25) {
          toast({
            title: "Tamanho Marca Inválida",
            description: "A marca não pode exceder 25 caracteres.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return false;
        }
        break;
      case 'color':
        if (value.length > 20) {
          toast({
            title: "Tamanho Cor Inválida",
            description: "O nome da cor não pode exceder 20 caracteres.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };

  const handleAddOrEdit = async (type, newItem, setState, endpoint) => {
    if (!validateInput(type, newItem.name)) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const method = editing[type] ? 'PUT' : 'POST';
      const url = editing[type] ? `${API_BASE_URL}${endpoint}/${editing[type]}` : `${API_BASE_URL}${endpoint}`;
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newItem),
      });

      if (response.ok) {
        fetchData(endpoint, setState);
        resetForm(type);
      } else {
        alert(`Failed to add or update ${type}`);
      }
    } catch (error) {
      console.error(`Error adding or updating ${type}:`, error);
    }
  };

  const resetForm = (type) => {
    switch (type) {
      case 'size':
        setNewSize('');
        setEditing({ ...editing, size: null });
        break;
      case 'brand':
        setNewBrand('');
        setEditing({ ...editing, brand: null });
        break;
      case 'color':
        setNewColor({ name: '', hex: '#000000' });
        setEditing({ ...editing, color: null });
        break;
      default:
        break;
    }
  };

  const handleDelete = async (endpoint, id, setState) => {
    setIsDeleteDialogOpen(false);
    if (deleteItem.name !== confirmName) {
      toast({
        title: "Nome incorreto",
        description: "O nome inserido não corresponde ao nome do item.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}${endpoint}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        alert('Failed to delete item');
      } else {
        fetchData(endpoint, setState);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const confirmDelete = (type, item) => {
    setDeleteType(type);
    setDeleteItem(item);
    setIsDeleteDialogOpen(true);
  };

  const handleEdit = (type, item) => {
    switch (type) {
      case 'size':
        setNewSize(item.name);
        setEditing({ ...editing, size: item._id });
        break;
      case 'brand':
        setNewBrand(item.name);
        setEditing({ ...editing, brand: item._id });
        break;
      case 'color':
        setNewColor({ name: item.name, hex: item.hex });
        setEditing({ ...editing, color: item._id });
        break;
      default:
        break;
    }
  };

  const resetDeleteDialog = () => {
    setConfirmName('');
    setIsDeleteDialogOpen(false);
  };

  return (
    <Box>
      <SimpleGrid columns={3} spacing={10}>
        {/* Manage Sizes */}
        <Box>
          <Heading size="md" color={brandColor}>{editing.size ? 'Editar Tamanho' : 'Adicionar Tamanho'}</Heading>
          <VStack align="start">
            <FormControl>
              <FormLabel>Tamanho</FormLabel>
              <Input value={newSize} onChange={(e) => setNewSize(e.target.value)} />
            </FormControl>
            <Button colorScheme="blue" onClick={() => handleAddOrEdit('size', { name: newSize }, setSizes, 'api/sizes')}>{editing.size ? 'Atualizar' : 'Adicionar'}</Button>
          </VStack>
          <Box mt={4}>
            <Heading size="sm" color={brandColor}>Lista de Tamanhos</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {sizes.map(size => (
                  <Tr key={size._id}>
                    <Td>{size.name}</Td>
                    <Td>
                      <IconButton
                        icon={<EditIcon />}
                        mr={2}
                        onClick={() => handleEdit('size', size)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => confirmDelete('size', size)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        {/* Manage Brands */}
        <Box>
          <Heading size="md" color={brandColor}>{editing.brand ? 'Editar Marca' : 'Adicionar Marca'}</Heading>
          <VStack align="start">
            <FormControl>
              <FormLabel>Marca</FormLabel>
              <Input value={newBrand} onChange={(e) => setNewBrand(e.target.value)} />
            </FormControl>
            <Button colorScheme="blue" onClick={() => handleAddOrEdit('brand', { name: newBrand }, setBrands, 'api/brands')}>{editing.brand ? 'Atualizar' : 'Adicionar'}</Button>
          </VStack>
          <Box mt={4}>
            <Heading size="sm" color={brandColor}>Lista de Marcas</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {brands.map(brand => (
                  <Tr key={brand._id}>
                    <Td>{brand.name}</Td>
                    <Td>
                      <IconButton
                        icon={<EditIcon />}
                        mr={2}
                        onClick={() => handleEdit('brand', brand)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => confirmDelete('brand', brand)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        {/* Manage Colors */}
        <Box>
          <Heading size="md" color={brandColor}>{editing.color ? 'Editar Cor' : 'Adicionar Cor'}</Heading>
          <VStack align="start">
            <FormControl isRequired>
              <FormLabel>Nome da Cor</FormLabel>
              <Input value={newColor.name} onChange={(e) => setNewColor({ ...newColor, name: e.target.value })} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Cor</FormLabel>
              <ChromePicker color={newColor.hex} onChange={(color) => setNewColor({ ...newColor, hex: color.hex })} />
            </FormControl>
            <Button colorScheme="blue" onClick={() => handleAddOrEdit('color', newColor, setColors, 'api/colors')}>{editing.color ? 'Atualizar' : 'Adicionar'}</Button>
          </VStack>
          <Box mt={4}>
            <Heading size="sm" color={brandColor}>Lista de Cores</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Hex</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {colors.map(color => (
                  <Tr key={color._id}>
                    <Td>{color.name}</Td>
                    <Td>
                      <Box w={6} h={6} bg={color.hex} borderRadius="50%" />
                    </Td>
                    <Td>
                      <IconButton
                        icon={<EditIcon />}
                        mr={2}
                        onClick={() => handleEdit('color', color)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => confirmDelete('color', color)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </SimpleGrid>

      <AlertDialog
  isOpen={isDeleteDialogOpen}
  leastDestructiveRef={cancelRef}
  onClose={resetDeleteDialog}
>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Confirmar Exclusão
      </AlertDialogHeader>

      <AlertDialogBody>
        Tem certeza que deseja excluir <strong>{deleteItem?.name}?</strong> Por favor, insira o nome do item para confirmar.
        <Input
          placeholder="Nome do item"
          value={confirmName}
          onChange={(e) => setConfirmName(e.target.value)}
        />
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button ref={cancelRef} onClick={resetDeleteDialog}>
          Cancelar
        </Button>
        <Button
          colorScheme="red"
          onClick={() => handleDelete(`api/${deleteType}s`, deleteItem._id, deleteType === 'size' ? setSizes : deleteType === 'brand' ? setBrands : setColors)}
          ml={3}
        >
          Excluir
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>
    </Box>
  );
};

export default ManageAttributes;
