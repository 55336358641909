// LineChartComponent.js

import React, { useState } from 'react';
import { Box, Select } from '@chakra-ui/react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip, Legend } from 'chart.js';
import { dayData, weekData, monthData } from './MockLineChartData';

// Register the required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

const LineChartComponent = () => {
  const [timeRange, setTimeRange] = useState('Day');

  const getChartData = () => {
    switch (timeRange) {
      case 'Day':
        return dayData;
      case 'Weeks':
        return weekData;
      case 'Month':
        return monthData;
      default:
        return dayData;
    }
  };

  const chartData = getChartData();

  const combinedChartData = {
    labels: chartData.labels,
    datasets: [
      ...chartData.datasets,
      {
        type: 'bar',
        label: 'Combined',
        data: chartData.datasets[0].data.map((_, index) =>
          chartData.datasets.reduce((sum, dataset) => sum + dataset.data[index], 0)
        ),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box bg="gray.700" p={0} borderRadius="md">
      <Select
        mb={5}
        value={timeRange}
        onChange={(e) => setTimeRange(e.target.value)}
        bg="gray.800"
        color="yellow"
        borderColor="blue.500"
        _hover={{ borderColor: 'blue.300' }}
        _focus={{ borderColor: 'blue.300' }}
        sx={{
          option: {
            backgroundColor: 'gray.800',
            color: 'yellow',
          },
        }}
      >
        <option value="Day">Dia</option>
        <option value="Weeks">Semanas</option>
        <option value="Month">Mês</option>
      </Select>
      <Line data={combinedChartData} />
      <Bar data={combinedChartData} />
    </Box>
  );
};

export default LineChartComponent;
