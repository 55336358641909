import React, { useEffect, useState } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from '../../App';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const AppWrapper = () => {
  const [customTheme, setCustomTheme] = useState(extendTheme());

  const fetchTheme = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/theme/get`);
      if (!response.ok) {
        throw new Error('Failed to fetch theme');
      }
      const data = await response.json();
      if (!data || Object.keys(data).length === 0) {
        const defaultTheme = extendTheme({
          colors: {
            brand: {
              50: 'blue.50',
              100: 'blue.100',
              200: 'blue.200',
              300: 'blue.300',
              400: 'blue.400',
              500: 'blue.500',
              600: 'blue.600',
              700: 'blue.700',
              800: 'blue.800',
              900: 'blue.900',
            },
          },
          fonts: {
            heading: 'Arial',
            body: 'Arial',
          },
        });
        setCustomTheme(defaultTheme);
      } else {
        setCustomTheme(extendTheme(data));
      }
    } catch (error) {
      const defaultTheme = extendTheme({
        colors: {
          brand: {
            50: 'blue.50',
            100: 'blue.100',
            200: 'blue.200',
            300: 'blue.300',
            400: 'blue.400',
            500: 'blue.500',
            600: 'blue.600',
            700: 'blue.700',
            800: 'blue.800',
            900: 'blue.900',
          },
        },
        fonts: {
          heading: 'Arial',
          body: 'Arial',
        },
      });
      setCustomTheme(defaultTheme);
    }
  };

  useEffect(() => {
    fetchTheme();
  }, []);

  const updateTheme = async (newTheme) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/theme`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ colors: newTheme.colors, fonts: newTheme.fonts }),
      });

      if (!response.ok) {
        throw new Error('Failed to update theme');
      }

      setCustomTheme(newTheme);
    } catch (error) {
      console.error('Failed to update theme:', error);
    }
  };



  return (
    <ChakraProvider theme={customTheme}>
      <App setCustomTheme={updateTheme} />
    </ChakraProvider>
  );
};

export default AppWrapper;
