import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  VStack,
  HStack,
  IconButton,
  Text,
  Image,
  useToast,
} from '@chakra-ui/react';
import Select from 'react-select';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ManageRelatedProducts = () => {
  const { carouselItemId } = useParams();
  const [allProducts, setAllProducts] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [carouselItems, setCarouselItems] = useState([]);
  const [selectedCarouselItem, setSelectedCarouselItem] = useState(carouselItemId || '');
  const toast = useToast();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/productsList/all`);
        const data = await response.json();
        setAllProducts(Array.isArray(data.products) ? data.products : []);
        console.log(allProducts);

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const fetchRelatedProducts = async () => {
      if (!selectedCarouselItem) return;
      try {
        const response = await fetch(`${API_BASE_URL}api/uppercarousel/related-products/${selectedCarouselItem}`);
        const data = await response.json();
        setRelatedProducts(data.relatedProducts || []);
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    const fetchCarouselItems = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/uppercarousel`);
        const data = await response.json();
        setCarouselItems(data);
      } catch (error) {
        console.error('Error fetching carousel items:', error);
      }
    };

    fetchProducts();
    fetchCarouselItems();
    if (selectedCarouselItem) {
      fetchRelatedProducts();
    }
  }, [selectedCarouselItem]);

  const addRelatedProduct = () => {
    if (!selectedProduct) {
      toast({
        title: "Select a product",
        description: "You must select a product to add",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const product = allProducts.find(p => p._id === selectedProduct);
    if (relatedProducts.some(p => p._id === product._id)) {
      toast({
        title: "Product already added",
        description: "This product is already in the related products list",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setRelatedProducts([...relatedProducts, product]);
    setSelectedProduct('');
  };

  const removeRelatedProduct = (index) => {
    const updatedRelatedProducts = relatedProducts.filter((_, i) => i !== index);
    setRelatedProducts(updatedRelatedProducts);
  };

  const saveRelatedProducts = async () => {
    if (!selectedCarouselItem) {
      toast({
        title: "Select a carousel item",
        description: "You must select a carousel item to save related products",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}api/uppercarousel/related-products/${selectedCarouselItem}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ relatedProducts: relatedProducts.map(p => p._id) }),
      });

      if (!response.ok) {
        throw new Error('Failed to save related products');
      }

      toast({
        title: "Success",
        description: "Related products saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving related products:', error);
      toast({
        title: "Error",
        description: "Failed to save related products",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <VStack spacing={4}>
        <FormControl>
          <FormLabel>Selecione o item de Carrossel</FormLabel>
          <ChakraSelect
            placeholder="Selecione Carrossel item"
            value={selectedCarouselItem}
            onChange={(e) => setSelectedCarouselItem(e.target.value)}
          >
            {carouselItems.map(item => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
          </ChakraSelect>
        </FormControl>
        <FormControl>
          <FormLabel>Selecione o Produto</FormLabel>
          <Select
            placeholder="Selecione o Produto"
            options={allProducts.map(product => ({ value: product._id, label: product.name }))}
            value={allProducts.find(product => product._id === selectedProduct)}
            onChange={(selectedOption) => setSelectedProduct(selectedOption.value)}
          />
        </FormControl>
        <Button leftIcon={<AddIcon />} onClick={addRelatedProduct}>
          Adicionar produto relacionado
        </Button>
        <VStack spacing={2} w="full">
          {relatedProducts.map((product, index) => (
            <HStack key={product._id} w="full" justifyContent="space-between">
              <Text>{product.name}</Text>
              <Image src={product.imageUrl} boxSize="50px" />
              <IconButton color={"red"} icon={<DeleteIcon />} onClick={() => removeRelatedProduct(index)} />
            </HStack>
          ))}
        </VStack>
        <Button colorScheme="blue" onClick={saveRelatedProducts}>
          Salvar
        </Button>
      </VStack>
    </Box>
  );
};

export default ManageRelatedProducts;
