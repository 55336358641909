import React, {useState, useEffect} from 'react';
import { Box, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, Button } from '@chakra-ui/react';
import { ChatIcon, HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { FaUserAlt, FaShoppingCart } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../User/UserContext';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const FixedBottomNavbar = ({ onToggle, isOpen }) => {
  const { user, setUser } = useUser();
  const [contacts, setContacts] = useState({
    whatsapp: 'Loading...',
    email: 'Loading...',
  });
  const navigate = useNavigate();
  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/contacts`);
      const data = await response.json();
      setContacts(data);
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  };

  const handleAccountClick = () => {
    if (user?.isAdmin) {
      navigate('/admin');
    } else {
      navigate('/account');
    }
  };

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      bg="gray.700"
      display={{ base: 'flex', md: 'none' }}
      justifyContent="space-around"
      alignItems="center"
      p={2}
      zIndex="1000"
    >
      <IconButton
        onClick={onToggle}
        icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
        variant={'ghost'}
        aria-label={'Toggle Navigation'}
        bg="transparent"
        color="white"
        _hover={{ bg: 'transparent' }}
      />
      <Popover trigger="hover">
        <PopoverTrigger>
          <IconButton aria-label="Chat" icon={<ChatIcon />} bg="transparent" color="white" _hover={{ bg: 'transparent' }} />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
          <Button as="a" href={`mailto:${contacts.email}`} variant="ghost" width="100%">{contacts.email}</Button>
          <Button as="a" href={`https://wa.me/${contacts.whatsapp}`} variant="ghost" width="100%" mt={2}>WhatsApp Chat</Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <Popover trigger="hover">
        <PopoverTrigger>
          <IconButton aria-label="User Account" icon={<FaUserAlt />} bg="transparent" color="white" _hover={{ bg: 'transparent' }} />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            {user ? (
              <>
                <Button onClick={handleAccountClick} variant="ghost" width="100%">Minha conta</Button>
                <Button onClick={handleLogout} variant="ghost" width="100%" mt={2}>Sair</Button>
              </>
            ) : (
              <>
                <Button as={Link} to="/register" variant="ghost" width="100%">Criar uma conta</Button>
                <Button as={Link} to="/login" variant="ghost" width="100%" mt={2}>Iniciar sessão</Button>
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <IconButton as={Link} to="/cart" aria-label="Shopping Cart" icon={<FaShoppingCart />} bg="transparent" color="white" _hover={{ bg: 'transparent' }} />
    </Box>
  );
};

export default FixedBottomNavbar;
